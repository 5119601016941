<!-- <html> -->

<template>
  <div
    class="genre"
    :class="isMuted ? '' : 'genre-active'"
    @click="mute(!isMuted)"
  >
    <div class="title">
      <p>{{ genre.title }}</p>
    </div>
  </div>
</template>

<script>
import { store } from '../store/index.js'

export default {

  name: 'Genre',

  props: ['id'],

  data () {
    return {
      store,
    }
  },

  methods: {
    mute() {
      const isMuted = this.isMuted

      for (
        let i = this.$root.position;
        i <= this.$root.mix.datas.length - 1;
        i++
      ) {
        for (let instrument of this.$root.genres[this.genre.id]) {
          this.mix.datas[i].mix[instrument] = !isMuted
        }
      }

      if (!this.isMuted) {
        for (const ref in this.$parent.$refs) {
          const section = this.$parent.$refs[ref][0]

          const isNotThis = section.id != this.id
          const hasRythmicRole = section.hasRythmicRole
          const isMuted = section.isMuted

          if (isNotThis && hasRythmicRole && !isMuted) {
            section.mute()
          }
        }
      }
    },
  },

  computed: {
    genre: function() {
      return this.$Datas.remix[ this.id ]
    },
    isPlaying: function() {
      return this.store.mainControl == 'play'
    },
    rythmic: function() {
      return this.store.rythmic
    },
    hasRythmicRole: function() {
      return true
    },
    mode: function() {
      return this.$root.mode
    },
    selected: function() {
      return this.$parent.selected
    },
    backgroundIsActive() {
      if (this.$root.mode == 'mix') {
        return !this.isMuted
      }
      else if (this.$root.mode == 'info') {
        return this.isPlaying && !this.isMuted
      }
    },

    mix: {
      get: function() {
        return this.$root.mix
      },
      set: function(mix) {
        this.$root.mix = mix
      }
    },

    position() {
      return this.$root.position
    },

    sectionIsMuted() {
      return this.$root.sectionIsMuted
    },

    isMuted() {
      if (this.position == null) {
        return null
      }

      if (this.sectionIsMuted == null) {
        return null
      }

      return this.sectionIsMuted[this.position][this.genre.id]
    }
  },

  // watch: {},

  // created () {},

  // mounted() {}

}
</script>

<style lang="scss" scoped>

  .genre {
    width: 20vw;
    height: 18vh;
    color: white;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
    text-align: center;
    background: #050209;
    border-radius: 3vw;
    cursor: pointer;
  }

  .genre-active {
    background: #f0b5c9;
  }

  .title {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    text-transform: uppercase;
  }

</style>
