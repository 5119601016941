<!-- <html> -->

<template>
  <div class="instrument" :class="id">

    <div class="background">
      <img
        :src="require('../assets/images/backgrounds/instrument' +
        ( descriptionIsSeen ? '-active' : '' )
        + '.png')"
      >
    </div>

    <div class="title">

      <div class="title-background">
        <img
          src="../assets/images/backgrounds/instrument_title.png"
          style="width:inherit;height:inherit"
        >
      </div>
      <div class="">
        {{ instrument.title }}
      </div>

    </div>

    <div v-if="!descriptionIsSeen" @click="showDescription()" style="width:inherit">

      <div class="content">
        <img :src="require('../assets/images/instruments_hands/' + instrument.files + '.png')">
      </div>

      <div class="infos">
        <img src="../assets/images/buttons/info_blue.png">
      </div>

    </div>

    <div v-else @click="hideDescription()" style="width:inherit">

      <div class="content">
        <p>{{ instrument.description }}</p>
      </div>

      <div class="infos">
        <img
          :src="require('../assets/images/buttons/'
                + ( isPlaying ? 'pause' : 'play' )
                + '.png')"
          @click.stop="!isPlaying ? play() : stop()"
        >
        <!-- <img
          src="../assets/images/buttons/info_blue_close.png"
        > -->
      </div>


    </div>

  </div>
</template>

<script>
export default {

  name: 'Instrument',

  props: ['id', 'instrument'],

  data () {
    return {
      descriptionIsSeen: false,
      isPlaying: false
    }
  },

  methods: {
    showDescription() {
      this.descriptionIsSeen = true

      for ( let key in this.$parent.$refs ) {
        let instrument = this.$parent.$refs[key][0]
        if ( instrument.id != this.id && instrument.descriptionIsSeen == true ) {
          instrument.hideDescription()
        }
      }
    },

    hideDescription() {
      this.descriptionIsSeen = false
      if ( this.isPlaying ) {
        this.stop()
      }
    },

    play() {
      this.$Player.solo( this.id )
      this.$Player.play()
      this.isPlaying = true
    },

    stop() {
      this.$Player.stop()
      this.$Player.unsolo()
      this.isPlaying = false
    }

  },

  // computed: {
  //
  // },

  // watch: {
  //
  // },

  // created () {
  //
  // },

  // mounted () {
  //
  // }

}
</script>

<style scoped>

  .instrument {
    /* width: 22vw; */
    /* height: 70vh; */
    cursor: pointer;
    border-radius: 20px;
  }

  .violin .content img {
    width: 12vw;
    height: auto;
  }
  .viola .content img {
    width: 14vw;
    height: auto;
  }
  .cello .content img {
    width: 18vw !important;
    height: auto;
  }
  .contrabass .content img {
    width: 22vw !important;
    height: auto;
  }
  .horn .content img {
    width: 18vw;
    height: auto;
  }
  .trumpet .content img {
    width: 12vw;
    height: auto;
  }

  .background {
    position: absolute;
    width: 20vw;
    height: 70vh;
    z-index: -1;
  }

  .background img {
    width: inherit;
    height: inherit;
  }

  .title {
    font-family: 'Londrina Light';

    min-height: 12vh;
    text-transform: uppercase;
    font-size: 1.5vw;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-background {
    position: absolute;
    z-index: -1;
    width: 15vw;
    height: 12vh;
  }

  .content {
    height: 45vh;
    display: flex;
    align-items: center;
    /* border: 1px solid blue; */
  }

  .content img {
    height: 35vh;
    width: auto;
    margin: auto;
  }

  .content p {
    font-size: 1.35vw;
    /* padding: 1vw; */
    text-align: left;
    text-indent: 2vw;
    /* border: 1px solid blue; */
    width: 80%;
    margin: auto;
  }

  .description {

  }

  .audio img {
    width: 6vw;
    height: auto;
  }

  .infos {
    width: inherit;
    /* border: 1px solid red; */
  }

  .infos img {
    width: 6vw;
    /* height: auto; */
  }

  .beige {
    background: #fdf8dc;
  }

  .rose {
    background: #f1b5ca;
  }

  .normal {

  }

  .low {
    background: #ddd;
  }

</style>
