<!-- <html> -->

<template>

  <div
    class="family"
  >
  <!-- @click="mute(!isMuted)" -->
  <!-- @click="mode == 'mix' ? clickAction() : ''" -->
  <!-- @click="isPlaying && mode == 'mix'
          ? ( isMuted ? unmute() : mute() )
          : null" -->

    <div class="backgrounds" :class="'bg-' + family.id">

        <img
          :src="require('../assets/images/backgrounds/families/'
           + family.id
           + (backgroundIsActive ? '_active' : '')
           + '.png'
          )"
          style="width:inherit;height:inherit;"
        /> <!-- ( isPlaying && !isMuted ? '_active' : '' ) -->

    </div> <!-- END .backgrounds -->


    <div v-show="mode == 'info' && !isPlaying"
      class="family-title"
      :class="'family-title-' + family.id"
    >
      <img :src="require('../assets/images/titles/' + family.id + '.png')"
        style="width:inherit;height:inherit;">
    </div>


    <div class="instruments" :class="family.id">

      <div
        v-for="instrument in family.instruments"
        :class="instrument.id"
        class="instrument"
        @click="mute(!isMuted)"
      >

        <img
          :src="require('../assets/images/instruments/'
            + instrument.files
            + '.png'
          )"
          :class="isPlaying
                  ? ( isMuted ? 'muted' : 'unmuted shake' )
                  : 'unmuted'"
        /> <!-- :class="isPlaying && isMuted ? 'muted' : 'unmuted'" -->

      </div>

      <div
        class="infos infos-family-classic"
        @click.stop="openModal"
        v-show="mode == 'info' && !isPlaying"
      >
        <img src="../assets/images/buttons/info_blue.png">
      </div>

    </div>

    <!-- FAMILY MODAL -->
    <div v-if="modal" id="modal">

      <div id="modal-close" @click="closeModal()">
        <img src="../assets/images/buttons/info_blue_close.png">
      </div>

      <div id="modal-title">
        <div id="modal-title-background">
          <img
            src="../assets/images/backgrounds/family_title.png"
            style="width:inherit;height:inherit"
          >
        </div>
        <div class="">
          {{ family.title }}
        </div>
      </div>

      <div class="instruments">

          <instrument
            v-for="instrument in family.instruments"
            v-bind:key="instrument.id"
            v-bind:id="instrument.id"
            v-bind:instrument="instrument"
            v-bind:ref="instrument.id"
          ></instrument>

      </div>

      <div id="modal-description">
        <p>{{ family.description }}</p>
      </div>

    </div> <!-- END v-if="modal" -->

  </div>

</template>

<script>
import { store } from '../store/index.js'

import Instrument from '../components/Instrument.vue'

export default {
  name: 'Family',

  components: {
    Instrument
  },

  props: ['id'],

  data () {
    return {
      store,

      modal: false,
    }
  },

  methods: {
    mute() {
      const isMuted = this.isMuted

      for (
        let i = this.$root.position;
        i <= this.mix.datas.length - 1;
        i++
      ) {
        for (let instrument in this.family.instruments) {
          this.mix.datas[i].mix[instrument] = !isMuted
        }
      }

      if (!this.isMuted && this.id == 'percussions') {
        for (const ref in this.$parent.$refs) {
          const section = this.$parent.$refs[ref][0]

          const isNotThis = section.id != this.id
          const hasRythmicRole = section.hasRythmicRole
          const isMuted = section.isMuted

          if (isNotThis && hasRythmicRole && !isMuted) {
            section.mute()
          }
        }
      }
    },

    openModal() {
      this.modal = true
      this.$Player.unmute( this.family )
    },

    closeModal() {
      this.modal = false

      for ( let key in this.$refs ) {
        let instrument = this.$refs[key][0]

        if ( instrument.descriptionIsSeen ) {
          instrument.hideDescription()
        }
      }
    }

  },

  computed: {
    family: function() {
      return this.$Datas.orchestra[ this.id ]
    },
    isPlaying: function() {
      return this.store.mainControl == 'play'
    },
    rythmic: function() {
      return this.store.rythmic
    },
    hasRythmicRole: function() {
      return this.id == 'percussions'
    },
    mode: function() {
      return this.$root.mode
    },
    currentSlideFamily: function() {
      return this.$parent.currentSlide
              ? this.$parent.currentSlide.family
              : null
    },
    selected: function() {
      return this.$root.selected
    },
    backgroundIsActive() {
      if (this.$root.mode == 'mix') {
        return !this.isMuted
      }
      else if (this.$root.mode == 'info') {
        return this.isPlaying && !this.isMuted
      }
    },

    mix: {
      get: function() {
        return this.$root.mix
      },
      set: function(mix) {
        this.$root.mix = mix
      }
    },

    position() {
      return this.$root.position
    },

    sectionIsMuted() {
      return this.$root.sectionIsMuted
    },

    isMuted() {
      if (this.position == null) {
        return null
      }

      if (this.mode == 'mix') {
        if (this.sectionIsMuted == null) {
          return null
        }

        return this.sectionIsMuted[this.position][this.family.id]
      }

      else if (this.mode == 'info') {
        if (
          this.currentSlideFamily == this.id
           || this.currentSlideFamily == 'tutti'
        ) {
          return false
        } else {
          return true
        }
      }
    }
  },

  // watch: {},

  // created() {},

  // mounted() {}

}

</script>

<style scoped>

  .family {
    height: 22vh;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .backgrounds {
    position: absolute;
    padding-top: 5vh;
    z-index: -1;
  }

  .bg-strings {
    width:80vw;
    height:100vh;
    left: 1vw;
    top: 2vh;
  }

  .bg-woodwinds {
    width:80vw;
    height:100vh;
    top: -3vh;
    left: 1vw;
  }

  .bg-brasses {
    width: 75vw;
    height: 70vh;
    left: 4vw;
    top: 12vh;
  }

  .bg-percussions {
    width:80vw;
    height:100vh;
    left: 12vw;
    top: -2vh;
  }

  .family-title {
    position: absolute;
    width: 16vw;
    height: auto;
  }

  .family-title-strings {
    left: 18vw;
    top: 70vh;
    transform: rotate(-30deg);
  }

  .family-title-woodwinds {
    left: 18vw;
    top: 50vh;
    transform: rotate(-18deg);
  }

  .family-title-brasses {
    left: 12vw;
    top: 32vh;
    transform: rotate(-30deg);
  }

  .family-title-percussions {
    left: 8vw;
    top: 12vh;
    transform: rotate(-36deg);
  }

  h2 {
    margin: 0;
    display: none;
  }

  .instruments {
    display: flex;
  }

  .instrument {
    width: 20vw;
    height: 20vh;
    cursor: pointer;
  }

  .instrument img {
    width: 14vmax;
    height: auto;
  }

  .instrument:nth-child(1), .instrument:nth-child(4) {
    margin-top: -5vh !important;
  }

  .instrument:nth-child(2), .instrument:nth-child(3) {
    margin-top: -15vh !important;
  }

  /* .instrument:nth-child(1) {
    transform: rotate(-20deg);
  }

  .instrument:nth-child(4) {
    transform: rotate(20deg);
  } */

  .violin img {
    width: 8vw !important;
    margin-top: 5vh;
    margin-left: 2vw;
  }
  .viola img {
    width: 10vw !important;
    margin-top: 3vh;
  }
  .cello img {
    /* width: 12vw !important; */
  }
  .contrabass img {
    width: 16vw !important;
    margin-left: -2vw;
  }
  .flute img, .oboe img, .clarinet img {
    width: 12vw !important;
    margin-top: 2vh;
  }
  .horn img {
    margin-top: 5vh;
    margin-left: 2vw;
  }
  .trumpet img {
    width: 8vw !important;
    margin-top: 5vh;
  }
  .tuba img {
    width: 16vw !important;
  }

  .infos {
    position: absolute !important;
  }

  .infos-family-classic {
    left: 35%;
    margin-top: -15vh;
    cursor: pointer;
  }

  .percussions .infos-family-classic {
    left: 15% !important;
    margin-top: 0 !important;
  }

  .infos-family-remix {
    left: 85%;
  }

  .infos img {
    width: 5vw;
    height: auto;
  }

  .muted {
    filter: brightness(.3);
    opacity: .8;
    transition: .5s;
    transform: scale(.7);
  }

  .unmuted {
    filter: brightness(1);
    transition: .5s;
    transform: scale(1);
    /* animation: shake 2s; */
    /* animation-iteration-count: infinite; */
  }

  .shake {
    animation: shake 2s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(-1deg); }
    20% { transform: rotate(1deg); }
    30% { transform: rotate(0deg); }
    40% { transform: rotate(1deg); }
    50% { transform: rotate(-1deg); }
    60% { transform: rotate(0deg); }
    70% { transform: rotate(-1deg); }
    80% { transform: rotate(1deg); }
    90% { transform: rotate(0deg); }
    100% { transform: rotate(-1deg); }
  }

  /* ----- */

  .strings {

  }

  .percussions {
    margin-left: -10vmax;
    width: 75vw;
  }

  .brasses .instrument {
    width: 18vmax;
  }

  .woodwinds .instrument:nth-child(1), .woodwinds .instrument:nth-child(4) {
    margin-top: 0 !important;
  }

  .woodwinds .instrument {
    width: 14vmax;
  }

  .woodwinds .instrument:nth-child(1), .woodwinds .instrument:nth-child(4) {
    margin-top: -5px !important;
  }

  .strings .instrument {
    width: 10vmax;
  }

  .string .instrument:nth-child(1), .strings .instrument:nth-child(4) {

  }

  .remix-family {
    width: 25vw;
    height: 25vh;
    color: white;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 20vw 20vh;
  }

  .remix-family-background {
    position: absolute;
    width: inherit;
    z-index: -1;
  }

  .remix-family p {
    font-family: 'Londrina Light';
    font-size: 2vmax;
    text-transform: uppercase;
    margin-top: 8vh;
  }

  .rock-inactive {
    background-image: url('../../src/assets/images/backgrounds/genres/rock.png');
  }

  .rock-active {
    background-image: url('../../src/assets/images/backgrounds/genres/rock_active.png');
    color: black !important;
  }

  .jazz-inactive {
    background-image: url('../../src/assets/images/backgrounds/genres/jazz.png');
  }

  .jazz-active {
    background-image: url('../../src/assets/images/backgrounds/genres/jazz_active.png');
    color: black !important;
  }

  .electro-inactive {
    background-image: url('../../src/assets/images/backgrounds/genres/electro.png');
  }

  .electro-active {
    background-image: url('../../src/assets/images/backgrounds/genres/electro_active.png');
    color: black !important;
  }

  /* Modal */

  #modal {
    background: #65c2cc;
    width: 100%;
    height: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  #modal-close {
    position: absolute;
    top: 0;
    right: 4vh;
  }

  #modal-close img {
    width: 8vw;
    height: auto;
  }

  #modal-title {
    text-transform: uppercase;
    font-family: 'Londrina Light';
    font-size: 2.5vw;

    min-height: 12vh;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  #modal-title-background {
    position: absolute;
    z-index: -1;
    width: 26vw;
    height: 12vh;
  }

  #modal .instruments {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
  }

  #modal .instrument {
    margin-top: 0 !important;
  }

  #modal-description {
    width: 70vw;
    margin-left: 15%;
    text-align: left;
    text-indent: 2vw;
    font-size: 1.5vw;
    position: fixed;
    bottom: 0;
  }

</style>
