<!-- <html> -->

<template>

  <div id="header">

    <div id="title" @click="toggleFullScreen()" >
      <!-- <p> -->
        {{ name }}
      <!-- </p> -->
      <!-- <p>&nbsp;&nbsp;</p>
      <p>
        <span @click="reload()" style="cursor:pointer;font-size:1vw">
          [ ReLoad ]
        </span>
        <span @click="toggleFullScreen()" style="cursor:pointer;font-size:.8vw">
          [ FullScreen ]
        </span>
      </p> -->

    </div>

    <div id="mode-control">
      <!-- MODE -->
      <button
        @click="mode = 'mix'"
        :class="mode == 'mix' ? 'button-active' : ''"
      >
        mix
      </button>

      <button
        @click="mode = 'info', display.main = 'orchestra'"
        :class="mode == 'info' ? 'button-active' : ''"
      >
        info
      </button>
    </div>

    <div id="display-control">
      <!-- VUE -->
      <button
        @click="display.main = 'orchestra'"
        :class="display.main == 'orchestra' ? 'button-active' : ''"
      >
        orchestre
      </button>

      <button
        @click="display.main = 'studio', mode = 'mix'"
        :class="display.main == 'studio' ? 'button-active' : ''"
      >
        studio
      </button>
    </div>

    <div id="nav" @click="modalSeen = 'nav'">
      <img :src="require('../assets/images/buttons/compass.png')" />
    </div>

    <div id="mix-menu">
      <span v-if="mix.title" @click="modalSeen = 'mix'">
        {{ mix.title }} (by {{ mix.user.username }})
      </span>
      <span
        v-else @click="modalSeen = profile ? 'mix' : 'auth'"
        style="border-bottom: 2px solid #888;"
      >
        J'enregistre mon mix !
      </span>

      <!-- <span
        @click="modalSeen = 'mix'"
        style="font-size:2vw;margin-right:1vw"
      >☰</span> -->

      <!-- <img
        :src="require('../assets/images/buttons/hamburger.png')"
        @click="modalSeen = 'mix'"
        style="width:2.5vw;height:auto"
      /> -->

      <img
        :src="require('../assets/images/buttons/'
          + (iLikeMix ? 'liked' : 'unliked') + '.png')"
        @click="iLikeMix ? unlike() : like()"
      />
      <small>{{ mix.id ? mix.likes.length : '0' }}</small>

      <img :src="require('../assets/images/buttons/comment.png')" />
      <small>{{ mix.id ? mix.comments.length : '0' }}</small>

      <!-- <img :src="require('../assets/images/buttons/save.png')" /> -->
      <!-- <img :src="require('../assets/images/buttons/share.png') /> -->
    </div> <!-- #mix-menu -->

    <div id="profile">
      <div id="avatar">
        <img
          :src="require('../assets/images/buttons/profile.png')"
          @click="modalSeen = 'auth'"
        />
      </div>

      <div id="username">
        {{ profile ? profile.username : 'Connexion' }}
      </div>
    </div>

  </div> <!-- #header -->

</template>

<script>
// import { store } from '../store/index.js'

export default {

  name: 'Header',

  props: ['id'],

  data () {
    return {
      // store,
    }
  },

  methods: {
    reload () {
      window.location.reload()
    },

    toggleFullScreen () {
      var doc = window.document
      var docEl = doc.documentElement

      var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl)
      } else {
        cancelFullScreen.call(doc)
      }
    },

    like () {
      this.$parent.like()
    },

    unlike () {
      this.$parent.unlike()
    }
  },

  computed: {
    name () {
      return this.$parent.name
    },
    version () {
      return this.$parent.version
    },

    mode: {
      get () {
        return this.$parent.mode
      },
      set (mode) {
        this.$parent.mode = mode
      }
    },

    modalSeen: {
      get () {
        return this.$parent.modalSeen
      },
      set (modalSeen) {
        this.$parent.modalSeen = modalSeen
      }
    },

    profile () {
      return this.$parent.profile
    },
    mix () {
      return this.$parent.mix
    },
    display () {
      return this.$parent.display
    },

    isPlaying () {
      return this.$parent.isPlaying
    },

    iLikeMix () {
      return this.$parent.iLikeMix
    },

  }
}

</script>

<style scoped>

  #header {
    background: #f0b5c9;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    font-size: 1.5vw;
    padding-left: 5vh;
    padding-right: 5vh;
  }

  #title {
    text-transform: uppercase;
    padding-top: .5vh;
    font-size: 2.5vw;
    width: 15vw;
    border-right: 1px solid #555;
    padding-right: 2vw;
  }

  /* #title p {
    margin: 0;
  } */

  #mode-control {
    width: 10vw;
    height: auto;
    padding-top: .5vh;
    cursor: pointer;
    text-align: center;
  }

  #display-control {
    width: 14vw;
    height: auto;
    padding-top: .8vh;
    cursor: pointer;
    text-align: center;
  }

  /* #nav {} */

  #nav img {
    width: 3vw;
    height: auto;
  }

  #mix-menu {
    font-size: 1.8vw;
  }

  #mix-menu img {
    width: 2.25vw;
  }

  #profile {
    display: flex;
    width: 10vw;
    text-align: right;
  }

  #avatar img {
    width: 3vw;
    height: auto;
  }

  #username {
    padding-top: .5vw;
    padding-left: .5vw;
    text-transform: uppercase;
  }

  button {
    background: #ddd;
    color: #222;
    font-size: 1.4vw;
    border: 0;
    text-transform: uppercase;
    font-family: inherit;
    min-width: 5vw;
    border-radius: 1vw;
    padding: .5vh;
  }

  .button-active {
    color: white !important;
    background: #f62d57 !important;
    font-size: 1.7vw !important;
  }

</style>
