<!-- <html> -->

<template>

  <div id="container">

    <div id="modal">

      <div id="close" @click="app.modalSeen = null">
        <img :src="require('../assets/images/buttons/info_blue_close.png')">
      </div>

      <div>
        <div v-if="mix.id == null || isOwner">
          <!-- v-if="mix.id == null || (isOwner && formularize.mix)" -->

          <form
            id="save-mix"
            @submit.prevent="mix.id == null ? createMix() : updateMix()"
          >
            <input
              type="text"
              name="title"
              v-model="form.title"
              placeholder="Mon mix génial"
            >
            <!-- <br> -->
            <!-- <button @click="formularize.mix = false">Annuler</button> -->
            <button
              id="save"
              type="submit"
              v-if="profile != null && (mix.id == null || isOwner)"
            >
              Enregistrer
            </button>
          </form>
        </div>
        <div v-else>
          <br>

          {{ mix.title ? mix.title : 'Mon nouveau mix génial' }}
          <button
            v-if="isOwner"
            id="edit"
            @click="formularize.mix == true"
            style="font-size:1.5vw"
          >
            Éditer
          </button>
          <!-- <button
            v-else
            id="fork"
            @click="forkMix()"
            style="font-size:1.5vw"
          >
            Fork
          </button> -->
        </div>

        <span style="font-size:1.7vw">
          par {{ mix.user.username ? mix.user.username : 'moi' }}
        </span>
        <!-- <br> -->
        <span style="font-size:1.4vw">
          d'après {{ mix.piece.title ? mix.piece.title : '.' }}
          de {{ mix.piece.composer ? mix.piece.composer : '.' }}
        </span>

        <br><br>

        <div
          v-if="mix.title"
          style="display:flex;font-size:2vw;justify-content:center"
        >
          <div>
            <img
              :src="require('../assets/images/buttons/'
                + (iLikeMix ? 'liked' : 'unliked') + '.png')"
              @click="iLikeMix ? unlike() : like()"
              style="width:3vw;"
            >
            {{ mix.title ? mix.likes.length : '-' }}
          </div>

          <div style="width:2vw"></div>

          <div style="font-size:2vw">
            <img
              :src="require('../assets/images/buttons/comment.png')"
              style="width:3vw;"
            >
            {{ mix.comments.length }}
          </div>

        </div>

        <div v-if="mix.title" style="font-size:1.5vw">
          Aimé par : <span v-for="like in mix.likes">
            {{ like.user.username }} .
          </span>
        </div>

        <br>

        <!-- <div style="font-size:2vw">
          <img
            :src="require('../assets/images/buttons/comment.png')"
            style="width:3vw;"
          >
          {{ mix.title ? mix.comments.length : '-' }}
        </div> -->

        <div id="comments">
          <div v-if="mix.title && mix.comments.length == 0">
            Ce mix n'a reçu encore aucun commentaire.
          </div>

          <table v-if="mix.title" style="margin:auto">
            <tr v-for="comment in comments">
              <td style="width:2vw">
                <img
                  :src="require('../assets/images/buttons/profile.png')"
                  style="width:2vw;margin:auto"
                >
              </td>
              <td style="min-width:10vw">{{ comment.user.username }} :</td>
              <td style="text-align:left">{{ comment.text }}</td>
            </tr>
          </table>
        </div>

        <div v-if="mix.title && profile != null">
          <form id="new-comment" @submit.prevent="newComment()">
            <textarea
              name="comment"
              v-model="newCommentText"
              placeholder="Mon commentaire"
              maxlength="2000"
            />
            <br>
            <button type="submit">
              Enregistrer mon commentaire
            </button>
          </form>
        </div>

      </div>

      <!-- <br> -->

      <button id="login" v-if="profile == null" @click="app.modalSeen = 'auth'">
        Inscrivez-vous ou connectez-vous
        <br />
        pour enregistrer votre nouveau mix
        <br />
        et poster des commentaires !
      </button>

      <br>

      <button id="explore" @click="app.modalSeen = 'nav'">
        Explorer tous les mixs
      </button>

      <button
        id="create"
        @click="app.resetMix(), app.modalSeen = null"
      >
        Créer un nouveau mix
      </button>

    </div> <!-- #modal -->

    <div id="background" @click="app.modalSeen = null"></div>
  </div> <!-- #container -->

</template>

<script>

export default {

  name: 'MixModal',

  props: ['id'],

  data () {
    return {
      formularize: {
        mix: false
      },

      newCommentText: ''
    }
  },

  methods: {

    createMix: function() {
      this.$Request.Post('/mix/create/', {
            user_id: this.profile.id,
            piece_id: 1,
            title: this.form.title,
            datas: this.mix.datas,
            isPublic: 1,
          })
          .then(response => console.log(response.data))
          .catch(e => console.log(e))
    },

    updateMix: function() {
      const data = {
        title: this.form.title
      }

      this.$Request.Put('/mix/' + this.mix.id + '/update/', data)
          .then(() => {
            this.mix.title = data.title
            this.formularize.mix = false
          })
    },

    forkMix: function() {
      this.mix.id = null
      this.mix.user = this.profile
    },

    newComment: function() {
      this.$Request.Post('/comment/create/', {
        user_id: this.profile.id,
        mix_id: this.mix.id,
        text: this.newCommentText
      })
      .then(() => {
        this.$Request.Get('/mix/' + this.mix.id + '/')
            .then(response => this.mix.comments = response.data.comments)

        this.newCommentText = ''
      })
    },

    like () {
      this.app.like()
    },

    unlike () {
      this.app.unlike()
    }
  },

  computed: {
    app: function() {
      return this.$root
    },
    profile: function() {
      return this.app.profile
    },
    mix: {
      get: function() {
        return this.app.mix
      },
      set: function(mix) {
        this.app.mix = mix
      }
    },
    isOwner: function() {
      if (this.profile) {
        return this.mix.user.username == this.profile.username
      }

      return false
    },
    form: function() {
      return {
        title: this.mix.title ? this.mix.title : null
      }
    },

    iLikeMix: function() {
      return this.app.iLikeMix
    },

    comments: function() {
      // return this.mix.comments.reverse()
      return this.mix.comments.sort((a, b) => {
          return b.id - a.id
      })
    }
  },

  // created() {}
}

</script>

<style scoped>

#container {
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  font-size: 1.6em;
}

#modal {
  width: 90vw;
  height: 90vh;
  margin: auto;
  margin-top: 5vh;
  font-size: 2vw;
  background: #f5c920;
  border-radius: 2vw;
  color: white;
}

#close {
  position: absolute;
  left: 90vw;
  top: .5vh;
}

#close img {
  width: 8vw;
  height: auto;
}

/* form#save-mix {
  border: 1px solid gray;
} */

#comments {
  width: 60vw;
  height: 25vh;
  margin: auto;
  padding: .5vw;
  overflow-y: scroll;
  border: 2px solid white;
  border-radius: 1vw;
  margin-bottom: 1vw;
  padding: 1vw;
  /* background: #e7bd1c; */
}

#comments table {
  width: inherit;
}

/* form#new-comment {} */

form#new-comment textarea {
  font-family: inherit;
  font-size: 2vw;
  resize: none;
  width: 60vw;
  height: 10vh;
}

form#new-comment button[type="submit"] {
  background: #f62d57;
  font-size: 2vw;
}

button#fork {
  background: #bbb;
  color: white;
  width: 5vw;
  height: 4vh;
  border-radius: .5vw;
}

input[type="text"] {
  font-size: 3vw;
  font-family: inherit;
  text-transform: uppercase;
  text-align: center;
  margin-top: 2vh;
}

button {
  border-radius: 1.5vw;
  border: 0;
  font-family: inherit;
  font-size: 5vh;
  color: white;
  text-transform: uppercase;
}

button#save {
  width: 15vw;
  height: 5vh;
  background: #f62d57;
  font-size: 2vw;
  margin-top: 1vh;
  margin-left: 1vh;
}

button#login {
  width: 40vw;
  height: 15vh;
  background: #65c1cc;
  border-radius: 1vw;
  font-size: 2vw;
}

button#explore, button#create {
  width: 30vw;
  height: 6vh;
  background: #65c1cc;
  border-radius: .5vw;
  margin: .5vw;
}

#background {
  width: inherit;
  height: inherit;
  background: white;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

</style>
