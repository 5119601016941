import Vex from 'vexflow'

export default {

  /* Datas */
  attributes: {},
  // factory: Vex.Flow.Factory,

  /* Methods */
  init: function(score) {
    // console.log('Sheet.init')

    const fifthsToScale = {
      '1': { 'major': 'G', 'minor': 'Em' },
      '0': { 'major': 'C', 'minor': 'Am' },
      '-1': { 'major': 'F', 'minor': 'Dm' }
    }

    // this.app.score.part[0].measure[0].attributes
    this.attributes = score.part[0].measure[0].attributes

    let timeSignature = this.attributes.time
    timeSignature = timeSignature['beats'] + '/' + timeSignature['beat-type']

    let key = this.attributes.key

    const VF = Vex.Flow

    var el = document.getElementById('sheet-head')
    el.innerHTML = ''

    var renderer = new VF.Renderer(el, VF.Renderer.Backends.SVG)
    renderer.resize(90, 120) // 90, 120
    var context = renderer.getContext()

    var stave = new VF.Stave(0, 0, 90) // 0, 0, 90
    stave
      .addClef("treble")
      .addKeySignature(fifthsToScale[key.fifths][key.mode])
      .addTimeSignature(timeSignature)

    stave.setContext(context).draw()

    document.querySelector('#sheet-head svg')
            .setAttribute(
              'style',
              'width: ' + window.innerWidth / 10 + 'px; height: 10vh;'
            )
  },

  renderAll: function(mix, mixedScore) {
    for (let i in mix) {
      let uuid = mix[i].uuid
      let measure = mixedScore[0][i].voices[1] // mixedScore[0][i].note
      this.render(uuid, measure, mix[i])
    }
  },

  render: function(uuid, measure, cell) {

    document.getElementById('measure-' + uuid).innerHTML = ''

    let vf = new Vex.Flow.Factory({renderer: {
        elementId: 'measure-' + uuid,
        width: 200,
        height: 120
      }
    })

    let score = vf.EasyScore({ throwOnError: true })

    const typeToDuration = {
      'whole': 1,
      'half': 2,
      'quarter': 4,
      'eighth': 8,
      '16th': 16,
      '32th': 32
    }

    let notes = []
    let fragment = []
    let mustBeConcat = false

    // console.log(measure)

    for (let n in measure) { // let n in measure
      let note = measure[n]

      /* BEAMS HELL START */
      let noteHasBeam = 'beam' in note
      let beamText = ''
      let nextNote = measure[parseInt(n)+1]

      if (noteHasBeam) {
        let noteBeam = Array.isArray(note.beam) ? note.beam[0] : note.beam
        beamText = noteBeam.__text
      }

      let duration = typeToDuration[note.type]

      if ('pitch' in note) {
        let noteToString = note.pitch.step

        if ('accidental' in note) {
          switch (note.accidental) {
            case 'sharp':
              noteToString += '#'
              break
            case 'flat':
              noteToString += 'b'
              break
            case 'natural':
              noteToString += 'n'
              break
            default:
              break
          }
        }

        noteToString += note.pitch.octave + '/' + duration

        if ('dot' in note) {
          noteToString += '.'
        }

        fragment.push(noteToString)
      }

      else if ('rest' in note) {
        fragment.push('B4/' + duration + '/r')
      }

      let mustBeFlushed = false

      if (beamText == 'end' || nextNote == null) {
        mustBeFlushed = true // 'true: end of beam'
      }

      else if (!noteHasBeam && nextNote != null && 'beam' in nextNote) {
        mustBeFlushed = true // 'true: next note is beam'
      }

      if (mustBeFlushed) {
        // console.log('mustBeFlushed:', fragment)

        const stem = (() => {
          const octave = fragment[0][1]

          if (octave <= 4) { return 'up' }
          if (octave >= 5) { return 'down' }

          return 'up'
        })()

        let parsedFragment = score.notes(fragment.join(', '), {stem: stem})

        if (noteHasBeam) {
          parsedFragment = score.beam(parsedFragment)
        }

        notes = mustBeConcat ? notes.concat(parsedFragment) : parsedFragment

        fragment = []
        mustBeConcat = true
      }

      /* BEAMS HELL END */
    }

    // notes = notes.join(', ')
    // notes = notes

    // document.getElementById('measure-' + uuid).innerHTML = ''
    //
    // let vf = new Vex.Flow.Factory({renderer: {
    //     elementId: 'measure-' + uuid,
    //     width: 200,
    //     height: 120
    //   }
    // })

    // let score = vf.EasyScore({ throwOnError: true })
    let system = ''

    let x = 0
    let y = 0

    function appendSystem(width) {
      const system = vf.System({ x, y, width, spaceBetweenStaves: 10 })
      x += width
      return system
    }

    let timeSignature = this.attributes.time

    var voice = new Vex.Flow.Voice({
      num_beats: timeSignature['beats'],
      beat_value: timeSignature['beat-type']
    })

    system = appendSystem(200)
    system = system.addStave({
      voices: [
        voice.addTickables(notes)
        // voice.addTickables(score.notes(notes))
        // score.voice(score.notes(notes))
      ]
    })

    system.setEndBarType('double')

    vf.draw()


    /* colors the notes if measure has FX */
    const color = 'red'

    if (cell.reverse == true || cell.tune != 0) {
      const selector = '#measure-' + uuid + ' path:not([stroke-width="1"])'

      document.querySelectorAll(selector).forEach((el) => {
          el.setAttribute('fill', color)
          el.setAttribute('stroke', color)
        })
    }

    /* resize */
    const width = window.innerWidth / 10

    document.querySelectorAll('.cell .measure svg').forEach((el) => {
      el.setAttribute('style', 'width: ' + width + 'px; height: 10vh;')
    })
  }
}

/**/

// var vf = ''
// var score = ''
// var system = ''
//
// vf = new Vex.Flow.Factory({renderer: {
//     elementId: this.el,
//     width: 150,
//     height: 500
//   }
// })
//
// score = vf.EasyScore()
// system = vf.System()
//
// system.addStave({
//   voices: [
//     score.voice(score.notes(scoreData))
//   ]
// })
// .addClef('treble')
// .addTimeSignature('4/4')
//
// vf.draw()
