import * as Tone from 'tone'

export default {

  /* Datas */
  app: {},
  sampler: [], //{},
  part: [], // {},
  time: 0,
  notesToAudio: [],
  alreadyRendered: false,

  /* Methods */
  load: function(app, score) {
    // console.log('Audio.load', window.location)

    this.app = app
    this.attributes = score.part[0].measure[0].attributes
    this.mixedScore = this.app.mixedScore

    // this.fileType = 'wav'

    this.samples = {
      bass_guitar: 'A#1-A#2-A#3-C#1-C#2-C#3-E1-E2-E3-E4-G#3-G1-G2',
      bassoon: 'A#1-A#2-A#3-A#4-C#2-C#3-C#4-C#5-E2-E3-E4-E5-G2-G3-G4',
      cello: 'A2-A3-A4-C2-C3-C4-C5-D#2-D#3-D#4-F#2-F#3-F#4',
      clarinet: 'B3-B4-B5-D3-D4-D5-D6-F3-F4-F5-G#3-G#4-G#5',
      contrabass: 'A1-A2-A3-C1-C2-C3-C4-D#1-D#2-D#3-F#1-F#2-F#3',
      drums: 'C5-F4-G5',
      electric_guitar: 'B2-B3-B4-B5-C#6-D3-D4-D5-E2-F3-F4-F5-G#2-G#3-G#4-G#5',
      electro_drums: 'C5-F4-G5',
      flute: 'A3-A4-A5-C3-C4-C5-D#3-D#4-D#5-F#3-F#4-F#5',
      horn: 'A#2-A#3-A#4-C#3-C#4-E2-E3-E4-G2-G3-G4',
      jazz_drums: 'C5-F4-G5',
      oboe: 'A#3-A#4-A#5-C#4-C#5-C#6-E4-E5-E6-G4-G5',
      percussions: 'C4-C5-G4',
      piano: 'A3-A4-A5-C3-C5-D#3-D#5-F#3-F#5',
      pizz_bass: 'A#1-C#2-E1-E2-G1',
      rock_drums: 'C5-F4-G5',
      synth_bass: 'C2-C3-C4-C5-C6-C7',
      synth_lead: 'C1-C2-C3-C4-C5-C6',
      trombone: 'A#1-A#2-A#3-A1-A2-A3-B1-B2-C2-C3-D#2-D#3-D2-D3-E3-F#1-F#2-F#3-F1-F2-F3-G#1-G#2-G#3-G1-G3',
      trumpet: 'A#3-A#4-A#5-C#4-C#5-C#6-E3-E4-E5-E6-G3-G4-G5',
      tuba: 'A#1-A#2-A#3-C#2-C#3-C#4-E1-E2-E3-G1-G2-G3',
      viola: 'A3-A4-A5-C3-C4-C5-C6-D#3-D#4-D#5-F#3-F#4-F#5',
      violin: 'A#3-A#4-A#5-A#6-C#4-C#5-C#6-E4-E5-E6-G3-G4-G5-G6',
    }

    const reverb = new Tone.Reverb(.5).toDestination()

    let loadedPartsCount = 0
    console.log('Loading parts start ...')

    this.sampler = score.part.map((part, p) => {
      const partName = score['part-list']['score-part'][p]['part-name']

      const instrument = (
        typeof partName === 'string' ? partName : 'piano'
      ).toLowerCase().split(' ')[0]
      // const instrument = 'drums'

      if (instrument == 'piano') { this.fileType = 'mp3' }

      let urls = {}
      this.samples[instrument].split('-').forEach((sample, i) => {
        const sampleFileName = sample.replace('#', 'S')

        urls[sample] = require(
          '/src/assets/audio/' + instrument + '/' + sampleFileName + '.mp3'
        )

        // console.log(instrument, urls[sample])
      })

      // console.log('______')

      // console.log('Loading audio cancelled');
      // return false

      // console.log(instrument + ' loading parts start ...')
      return new Tone.Sampler({
        urls: urls,
        // baseUrl: './assets/audio/' + instrument + '/',
        onload: () => {
          // console.log(instrument + ' loaded.')
          loadedPartsCount ++
          this.app.loading = loadedPartsCount / score.part.length * 100
          // console.log(loadedPartsCount)

          if (loadedPartsCount == score.part.length) {
            console.log('All parts are loaded (' + loadedPartsCount + ')')
          }
        }
      }).connect(reverb)
    })
  },

  render: function(i, mixedScore, renderMeasure = true) {
    // console.log('render', i, renderMeasure)

    const typeToDuration = {
      // 'whole': 2, 'half': 1, 'quarter': .5, 'eighth': .25, '16th': .125
      'whole': 4, 'half': 2, 'quarter': 1, 'eighth': .5, '16th': .25, '32nd': .125
    }

    for (let p in mixedScore) {

      let part = mixedScore[p]
      // let time = 0
      let lastNote = null

      if (this.notesToAudio[p] == null) {
        this.notesToAudio[p] = []
      }

      this.notesToAudio[p][i] = []

      let measure = part[i]

      // let notes = measure.voices[1] //measure.note
      // console.log(measure.voices[1])

      let measureStartTime = i * this.attributes.time.beats * (60/this.app.tempo) // time

      for (let voice in measure.voices) {
        let notes = measure.voices[voice]
        // console.log(notes)

        this.time = measureStartTime

        for (let n in notes) {
          let note = notes[n]

          let duration = typeToDuration[note.type] * (60 / this.app.tempo)

          if (note.dot != null) { // 'dot' in note
            duration = duration * 1.5
          }

          if (note.pitch != null) { // 'pitch' in note
            let noteName = note.pitch.step

            if (note.pitch.alter != null) {
              // 'alter' in note.pitch && mix[i].tune == 0

              switch (note.pitch.alter) {
                case '1':
                  noteName += '#'
                  break
                case '-1':
                  noteName += 'b'
                  break
                default:
                  break
              }
            }

            noteName += note.pitch.octave

            this.notesToAudio[p][i].push({ //this.notesToAudio[p].push({
              // name: note.pitch.step + note.pitch.octave,
              name: noteName,
              // duration: 1 / note.duration * 8 + 'n',
              duration: duration,
              // time: note.duration / 2,
              time: this.time
            })
          }

          this.time += duration
        }

      } // let voice in voices
    }
  },

  renderAll: function(mix, mixedScore) {
    // console.log('Audio.renderAll', this, 'app' in this, this.app)
    // return

    for (let i in mix) {
      // console.log(i)
      this.render(i, mixedScore, false)
    }

    const NotesToAudio = this.notesToAudio.map((part, p) => {
      return part.flat()
    })
    // console.log(this.notesToAudio)

    /**/

    Tone.Transport.bpm.value = this.app.tempo

    this.sampler.forEach((part, p) => {
      // console.log(part, p, this.part[p] instanceof Tone.Part)

      if (this.part[p] instanceof Tone.Part) {
        this.part[p].clear()
      }

      let noteToAudioPart = NotesToAudio[p]

      this.part[p] = new Tone.Part((time, noteToAudioPart) => {
        // console.log('Tone.Part', noteToAudioPart, time)

        this.sampler[p].triggerAttackRelease(
          noteToAudioPart.name,
          noteToAudioPart.duration,
          // noteToAudioPart.time,
      )}, noteToAudioPart).start(0)
    })

    if (this.alreadyRendered) {
      return
    } else {
      this.alreadyRendered = true
    }

    let timeSignature = this.attributes.time

    Tone.Transport.timeSignature = [
      this.attributes.time['beats'], this.attributes.time['beat-type']
    ] // [4, 4]

    this.part[0].mute = mix[0].mute

    /* Cell select */
    Tone.Transport.scheduleRepeat((time) => {
      let i = Tone.Transport.position.split(':')[0]
      this.app.selected = this.app.uuids[i]

      this.app.parts.forEach((part, p) => {
        if (this.part[p].mute != mix[i].mix[part.toLowerCase()]) {
          this.part[p].mute = mix[i].mix[part.toLowerCase()]
        }
      })
    }, '1m', '0:0:0')

    /* Mute & unmute */
    Tone.Transport.scheduleRepeat((time) => {
      let i = Tone.Transport.position.split(':')[0]

      if (this.app.nextCellUuid != null) {
        this.app.parts.forEach((part, p) => {
          this.part[p].mute = mix[parseInt(i) + 1].mix[part.toLowerCase()]
        })
      }
      else {
        this.stop()
        this.app.selected = this.app.uuids[0]
      }

    }, '1m', '0:' + (timeSignature['beats'] - 1) + ':0') // , '4m'
    // '1m', '0:' + timeSignature['beats'] + ':12'

    // Tone.Transport.scheduleRepeat((time) => {
    //   console.log('plop', time)
    // }, '4n', '0:0:0')
  },

  /**/

  play: function() {

    this.app.parts.forEach((part, p) => {
      let mute = this.app.mix.datas[this.app.position].mix[part.toLowerCase()]

      if (this.part[p].mute != mute) {
        this.part[p].mute = mute
      }
    })

    // Tone.Transport.bpm.value = this.app.tempo
    Tone.Transport.start() // Tone.now(), '2:0:0'
    Tone.start()
  },

  pause: function() {
    Tone.Transport.pause()
  },

  stop: function() {
    Tone.Transport.stop()
    this.app.selected = 0
  },

  seek: function(i) {
    // console.log('Audio.seek', i, this.app.mix.datas[i].mix)
    Tone.Transport.position = i + ':0:0'
  },

  playCell: function() {
    this.play()
    Tone.Transport.stop('+0:' + this.attributes.time.beats + ':0')
  },

  setTempo: function(tempo) {
    Tone.Transport.bpm.value = tempo
  },

  getState: function() {
    return Tone.Transport.state
  },

  mute: function() {
    console.log('Audio.mute')
  }
}
