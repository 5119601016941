export default {

  /* Datas */
  score: {},
  parts: {},
  tempo: null,

  /* Methods */
  init(datas) {
    /* Extract parts datas */
    const score = datas['score-partwise']

    if (!Array.isArray(score.part)) {
      let uniquePart = JSON.parse(JSON.stringify(score.part))
      score.part = []
      score.part[0] = uniquePart
      score['part-list']['score-part'][0] = score['part-list']['score-part']
    }

    this.score = score

    return this.score
  },

  getParts() {
    /* Extract parts names */
    let scoreParts = this.score['part-list']['score-part']

    if (Array.isArray(scoreParts)) {
      this.parts = scoreParts.map((part) => {
        return part['part-name']
            ? part['part-name'].split(' ')[0]
            : 'piano'
      })
    }
    else {
      this.parts = ['piano']
    }

    return this.parts
  },

  getTempo() {
    /* Extract tempo */
    let firstMeasure = this.score.part[0].measure[0]

    if (Array.isArray(firstMeasure.direction)) {
      firstMeasure.direction = firstMeasure.direction[0]
    }

    this.tempo = firstMeasure.direction
        ? parseInt(firstMeasure.direction.sound._tempo)
        : parseInt(firstMeasure.sound._tempo)

    return this.tempo
  },

  render(mix, score) {
    // console.log('Score.render', mix, score)

    this.mix = mix
    this.score = score

    if (this.score.part == null) {
      return null
    }

    // console.log('Array.isArray(this.mix)', Array.isArray(this.mix))
    return this.score.part.map((part, p) => {
      return this.mix.map((cell, i) => {

        // console.log(
        //   'p:' + p,
        //   'i:' + i,
        //   'source:' + cell.source,
        //   'reverse:' + cell.reverse,
        //   'tune:' + cell.tune,
        // part.measure[cell.source].note,
        // )

        let measure = JSON.parse(
          JSON.stringify(
            part.measure[cell.source]
          )
        )

        let notes = measure.note

        let voices = {}

        notes.forEach((note) => {
          if (voices[note.voice] == null) {
            voices[note.voice] = []
          }
          voices[note.voice].push(note)
        })

        // console.log(voices)

        for (let voice in voices) {
          // console.log(voices[voice])
          notes = voices[voice]

          /* REVERSE */
          if (cell.reverse) {

            let beamTextReverse = {
              'end': 'begin',
              'begin': 'end',
              'forward hook': 'backward hook',
              'backward hook': 'forward hook',
              'continue': 'continue'
            }

            notes = notes.reverse()

            notes = notes.map((note, n) => {

              if ('beam' in note) {
                if (Array.isArray(note.beam)) {
                  note.beam = note.beam.map((beam) => {
                    beam.__text = beamTextReverse[beam.__text]
                    return beam
                  })
                }

                else {
                  note.beam.__text = beamTextReverse[note.beam.__text]
                }
              }

              return note
            })
          }


          /* TUNE */
          if (cell.tune !== 0) {

            const octaves = [0, 1, 2, 3, 4, 5, 6, 7, 8]
            const steps = ['C', 'D', 'E', 'F', 'G', 'A', 'B']

            const range = octaves.map((octave) => {
              return steps.map((step) => {
                return step + octave
              })
            }).flat()

            const scalesAlters = {
              '-3': ['B', 'E', 'A'],
              '-2': ['B', 'E'],
              '-1': ['B'],
              '0': [],
              '1': ['F'],
              '2': ['F', 'C'],
              '3': ['F', 'C', 'G'],
            }
            const key = this.score.part[0].measure[0].attributes.key
            const alters = scalesAlters[key.fifths]
            const alter = key.fifths

            notes = notes.map((note) => {
              if ('rest' in note) {
                return note
              }

              const notePitch = note.pitch.step + note.pitch.octave
              const index = range.indexOf(notePitch) + cell.tune

              note.pitch.step = range[index][0]
              note.pitch.octave = range[index][1]

              if (note.pitch.alter != null) {
                note.pitch.alter = null
                note.accidental = null
              }

              if (alters.indexOf(note.pitch.step) != -1) {
                note.pitch.alter = alter
                note.accidental = { '-1': 'flat', '1': 'sharp' }[alter]
              }

              return note
            })
          }

        } // for (let voice in voices)

        measure.voices = voices
        // console.log(measure)

        return measure
      })
    })
  }
}
