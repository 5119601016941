<!-- <html> -->

<template>
  <div id="body">

    <div id="orientation-alert" v-show="orientation != 'landscape-primary'">
      <br /><br />
      - {{ name }} -
      <br /><br />
      Veuillez maintenir l'appareil
      <br />
      en position paysage
      <br />
      pour le bon fonctionnement
      <br />
      de l'application.
      <br />
      Merci.
    </div>

    <div id="loading-modal" v-show="loading < 100">
      <h1>{{ name }}</h1>
      <h2>Chargement en cours</h2>
      <br>
      <div id="loading-bar">
        <div id="fill" :style="'width:' + loading + '%'"></div>
      </div>
    </div>

    <div id="processing-modal" v-show="processing">
      <img :src="require('./assets/images/gifs/ellipsis.gif')">
    </div>

    <div
      id="installation-modal"
      v-show="false"
    > <!-- v-show="PWADisplayMode == 'browser'" -->
      <!-- && nodeEnv != 'development' -->
      <!-- <h1>Bienvenue dans {{ name }} !</h1> -->
      <div id="logo">
        <img :src="require('./assets/images/logo/orchestramix-320x200.png')">
      </div>

      <h2>L'application qui rend la musique classique déjantée !</h2>

      <div id="screens">
        <img :src="require('./assets/images/screens/info.png')">
        <img :src="require('./assets/images/screens/studio.png')">
      </div>

      <br><br>

      <div id="buttons">
        <button
          name="play"
          @click="PWADisplayMode = ''"
          v-show="!installationWaiting"
        >
          JOUER
        </button>

        <button
          name="install"
          @click="install()"
          v-show="!installationWaiting"
        >
          INSTALLER
        </button>
      </div>

      <div id="installation-waiting" v-show="installationWaiting">
        Installation en cours ...
        <br>
        <img :src="require('./assets/images/gifs/ellipsis.gif')">
      </div>
    </div>

    <app-header
      id="app-header"
      style="z-index:0"
    ></app-header>

    <orchestra
      id="orchestra"
      v-show="display.main == 'orchestra'"
      style="z-index:0"
    ></orchestra>

    <studio
      id="studio"
      v-show="display.main == 'studio'"
      style="z-index:0"
    ></studio>

    <nav-modal
      id="nav-modal"
      v-show="modalSeen == 'nav'"
      style="z-index:0"
    ></nav-modal>

    <mix-modal
      id="mix-modal"
      v-show="modalSeen == 'mix'"
      style="z-index:0"
    ></mix-modal>

    <auth-modal
      id="auth-modal"
      v-show="modalSeen == 'auth'"
      style="z-index:0"
    ></auth-modal>

  </div> <!-- END #body -->
</template>

<script>
import { store } from './store/index.js'

import NavModal from './components/NavModal.vue'
import MixModal from './components/MixModal.vue'
import AuthModal from './components/AuthModal.vue'

import AppHeader from './components/AppHeader.vue'
import Orchestra from './components/Orchestra.vue'
import Studio from './components/Studio.vue'

export default {

  name: 'App',
  components: {
    NavModal,
    MixModal,
    AuthModal,
    AppHeader,
    Orchestra,
    Studio,
  },

  props: [],

  data () {
    return {
      store,

      name: 'OrchestraMix',
      version: '0.0',

      modalSeen: null,

      mode: 'mix',

      orientation: null,

      profile: null,
      score: {},
      parts: {},

      tempo: null,

      mix: {
        isPublic: true,
        piece: {},
        title: null,
        user: {},
      },

      selected: 1001, // [ 0 - 1001 ]
      tempo: 0,

      display: {
        main: 'orchestra', // [ orchestra - studio ]
        sheet: true, // true
        graph: false, // false
      },

      processing: false,
      PWADisplayMode: '',
      installationWaiting: false,

      deferredPrompt: null,

      loading: 0,

      families: {
        percussions: ['percussions'],
        brasses: ['horn', 'trumpet', 'trombone', 'tuba'],
        woodwinds: ['flute', 'oboe', 'clarinet', 'bassoon'],
        strings: ['violin', 'viola', 'cello', 'contrabass'],
      },

      genres: {
        rock: ['electric_guitar', 'bass_guitar', 'rock_drums'],
        jazz: ['piano', 'pizz_bass', 'jazz_drums'],
        electro: ['synth_lead', 'synth_bass', 'electro_drums'],
      },

    }
  },

  methods: {

    /* Load datas & modules */
    load () {

        const score = require('./assets/scores/britten-orchestre-remix.json')

        this.score = this.$Score.init(score)
        this.parts = this.$Score.getParts()
        this.tempo = this.$Score.getTempo()

        /* Setting default mix */
        const datas = this.score.part[0].measure.map((mes, i) => {

          // const mix = {}
          // this.parts.forEach(part => mix[part.toLowerCase()] = false)

          const mix = {
            // classic
            horn: false, trumpet: false, trombone: false, tuba: false,
            flute: false, oboe: false, clarinet: false, bassoon: false,
            violin: false, viola: false, cello: false, contrabass: false,
            percussions: false,

            // rock
            electric_guitar: true, bass_guitar: true, rock_drums: true,

            // jazz
            piano: true, pizz_bass: true, jazz_drums: true,

            // electro
            synth_lead: true, synth_bass: true, electro_drums: true,
          }

          return {
            uuid: 1001 + i,
            source: i,
            mix: mix,
            reverse: false,
            tune: 0
          }
        })

        this.mix.datas = datas
        // console.log('setting default mix', this.mix)
        /**/

        /* init the modules */
        console.log('init the modules')
        // console.log('load Audio module')
        this.$Audio.load(this, this.score)

        // console.log('load Sheet module')
        if (this.display.sheet) {
          this.$Sheet.init(this.score)
        }

        // console.log('load Graph module')
        if (this.display.graph) {
          this.$Graph.init(this)
        }

        // console.log('load Player module')
        this.$Player.init(this.datas)
    },

    install: function() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
          this.installationWaiting = true
          setTimeout(() => { window.location.reload() }, 3000)
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        this.deferredPrompt = null
      })
    },

    resetMix: function() {
      this.mix = {
        isPublic: true,
        piece: {},
        title: null,
        user: {},
      }

      this.load()
    },

    like: function(mix = null) {
      if (mix == null) {
        mix = this.mix
      }

      if (this.profile == null) {
        this.modalSeen = 'auth'
        return
      }

      if (mix.title == null) {
        // this.modalSeen = 'nav'
        return
      }

      this.$Request.Post('/like/create/', {
            user_id: this.profile.id,
            mix_id: mix.id
          })
          .then((response) => {

            this.$Request.Get('/mix/' + mix.id + '/')
                .then(response => mix.likes = response.data.likes)

          })
          .catch(e => console.log(e))
    },

    unlike: function(mix = null) {
      if (mix == null) {
        mix = this.mix
      }

      let id = null

      mix.likes.forEach((like) => {
        if (like.user.username == this.profile.username) {
          id = like.id
        }
      })

      this.$Request.Delete('/like/' + id + '/delete/')
          .then((response) => {
            this.$Request.Get('/mix/' + mix.id + '/')
                .then(response => mix.likes = response.data.likes)
          })
    }
  },

  computed: {
    nodeEnv: function() {
      return process.env.NODE_ENV
    },

    datas: function() {
      return this.$Datas
    },

    isPlaying: function() {
      return this.store.mainControl == 'play'
    },

    /**/
    selectedCell: function() {
      for (let i in this.mix.datas) {
        let cell = this.mix.datas[i]
        if (cell.uuid == this.selected) {
          return cell
        }
      }
      return null
    },

    uuids: function() {
      return this.mix.datas.map((cell) => {
        return cell.uuid
      })
    },

    nextCellUuid: function() {
      if (this.selectedCell == null) {
        return this.uuids[0]
      }

      for (let i in this.uuids) {
        if (this.selectedCell.uuid == this.uuids[i]) {
          return this.uuids[parseInt(i) + 1] || null
        }
      }
    },

    position: function() {
      if (this.selectedCell) {
        return this.uuids.indexOf(this.selectedCell.uuid)
      }
      return null
    },

    mixedScore: function() {
      if (this.mix.datas != null && 'part' in this.score) {
        return this.$Score.render(this.mix.datas, this.score)
      }

      return null
    },

    sections: function() {
      let sections = {}

      for (let f in this.families) {
        sections[f] = this.families[f]
      }

      for (let g in this.genres) {
        sections[g] = this.genres[g]
      }

      return sections
    },

    sectionIsMuted: function() {
      if (this.mix.datas == null) {
        return null
      }

      return this.mix.datas.map((cell) => {
        let sectionIsMuted = {}

        for (let s in this.sections) {
          let value = true

          this.sections[s].forEach((instrument) => {
            if (cell.mix[instrument] == false) {
              value = false
            }
          })

          sectionIsMuted[s] = value
        }

        return sectionIsMuted
      })
    },

    iLikeMix: function() {
      if (this.profile == null) {
        return false
      }

      if (!this.mix.title) {
        return false
      }

      let iLikeMix = false

      this.mix.likes.forEach((like) => {
        if (like.user.username == this.profile.username) {
          iLikeMix = true
        }
      })

      return iLikeMix
    },

  },

  watch: {
    mode: function() {
      if ( this.mode == 'info' ) {
        this.$Player.tracks['tuttiOriginal'].volume(1)
        this.$Player.solo( 'tuttiOriginal' )
      }
      else if ( this.mode == 'mix' ) {
        this.$Player.unsolo( 'tuttiOriginal' )
        this.$Player.tracks['tuttiOriginal'].volume(0)
      }
    },

    /**/
    mixedScore: {
      handler: function(mixedScore) {

        if (this.mix.datas !== null || mixedScore !== null) {
          // this.processing = true

          setTimeout(() => {
            this.$Audio.renderAll(this.mix.datas, mixedScore)
            // this.$Sheet.renderAll(this.mix.datas, mixedScore)

            if (this.display.graph) {
              this.$Graph.renderAll()
            }

            // this.processing = false
          }, 10) //100
        }
      },
      deep: true
    },
  },

  created() {
    console.clear()

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e
    })

    this.PWADisplayMode = (() => {
      const isStandalone = window
          .matchMedia('(display-mode: standalone)')
          .matches

      if (document.referrer.startsWith('android-app://')) {
        return 'twa'
      } else if (navigator.standalone || isStandalone) {
        return 'standalone'
      }
      return 'browser'
    })()

    // console.log('init Request module')
    this.$Request.init(process.env.VUE_APP_BACKEND_URL)

    if (localStorage.getItem('token') !== null) {
      this.$Request.Get('/profile/')
          .then(response => this.profile = response.data)
    }

    this.mode = 'mix'

    this.orientation = window.screen.orientation.type

    // this.load()
  },

  mounted() {
    this.load()

    setTimeout(() => {
      if (this.display.sheet) {
        this.$Sheet.renderAll(this.mix.datas, this.mixedScore)
      }
      if (this.display.graph) {
        this.$Graph.renderAll()
      }

    }, 10)

    window.addEventListener('orientationchange', () => {
      this.orientation = window.screen.orientation.type
    })
  }

}
</script>

<style>

@font-face {
  font-family: 'Londrina Thin';
  src: url("./assets/fonts/londrina-thin.otf");
}

@font-face {
  font-family: 'Londrina Light';
  src: url("./assets/fonts/londrina-light.otf");
}

@font-face {
  font-family: 'Londrina Regular';
  src: url("./assets/fonts/londrina-regular.otf");
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  font-family: 'Londrina Thin';
  letter-spacing: 1px;
  background: #f6c920;
}

</style>

<style lang="scss" scoped>

  #body {
    width: 100vw;
    height: 100vh;
  }

  #orientation-alert {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: black;
    color: white;
    font-size: 8vw;
    text-align: center;
    z-index: 2;
    letter-spacing: 2px;
    line-height: 1.75;
  }

  #loading-modal {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: #f6c920;
    z-index: 1;
    text-align: center;
    padding-top: 15vh;
    font-size: 2vw;
  }

  #loading-bar {
    width: 60%;
    height: 4vh;
    border: 1px solid black;
    border-radius: 1vw;
    margin: auto;
  }

  #fill {
    height: inherit;
    background: red;
  }

  #processing-modal {
    width:100vw;
    height:100vh;
    position:absolute;
    left:0;
    top:0;
    padding-top: 40vh;
    text-align: center;
    font-size:8vw;
  }

  #processing-modal img {
    width: 30vw;
    height: auto;
    margin-top: -10vw;
  }

  #installation-modal {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 3vh;
    text-align: center;
    background: #65c2cc; /*#f6c920;*/
    color: white;
    z-index: 1;
  }

  #installation-modal #logo img {
    width: 20vw;
    height: auto;
  }

  /* #installation-modal h1 {
    font-size: 5vw;
    margin-bottom: 0;
  } */

  #installation-modal h2 {
    font-size: 3vw;
    margin-top: 0;
  }

  #installation-modal #screens {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 95vw;
  }

  #installation-modal img {
    width: auto;
    height: 35vh;
    margin: 0vw 2vw 0vw 2vw;
  }

  #installation-modal button {
    width: 18vw;
    height: 6vw;
    margin: 0vw 2vw 0vw 2vw;
    font-family: 'Arial';
    font-size: 2vw;
  }

  #installation-modal button[name="play"] {
    background: white;
    border: 4px solid #109d58;
    color: #109d58;
  }

  #installation-modal button[name="install"] {
    background: #109d58;
    border: 0;
    color: white;
  }

  #installation-modal button img {
    width: 3vw;
    height: auto;
  }

</style>
