import { reactive } from 'vue'

export const store = reactive({
  mainControl: 'stop',
  rythmic: 'percussions',

  setMainControl(value) {
    this.mainControl = value
  },

  setRythmic(value) {
    this.rythmic = value
  }
})
