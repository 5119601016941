export default {

  /* Datas */
  app: {},
  size: {
    width: 0,
    height: 0
  },
  range: [],
  // typeToDuration: {
  //   'whole': 1, 'half': 2, 'quarter': 1, 'eighth': .5, '16th': .25
  // },
  beats: 4,

  /* Methods */
  init: function(app) {
    // console.log('Graph init')

    this.app = app

    this.size.width = 200
    this.size.height = 100

    this.range = [1, 2, 3, 4, 5, 6].map((octave) => {
      return ['C', 'D', 'E', 'F', 'G', 'A', 'B'].map((step) => {
        return step + octave
      })
    }).flat()
  },

  renderAll: function() {
    // console.log('Graph render')

    // let indexMin = null
    // let indexMax = null

    this.app.uuids.forEach((uuid, i) => {

      document.getElementById('graph-' + uuid).innerHTML = ''

      let notes = this.app.mixedScore[0][i].voices[1]
        //this.app.mixedScore[0][i].note

      let lastNote = null

      notes.forEach((note) => {

        const element = document.createElement('div')
        const duration = {
          'whole': 1, 'half': 2, 'quarter': 1, 'eighth': .5, '16th': .25
        }[note.type]

        const width = Math.floor(
          (this.size.width * .9) * (duration / this.beats)
        )

        element.style.width = width + 'px'

        if ('pitch' in note) {
          const notePitch = note.pitch.step + note.pitch.octave
          const noteIndex = this.range.indexOf(notePitch)
          const hasFx = this.app.mix.datas[i].reverse || this.app.mix.datas[i].tune != 0

          element.style.height = '5px'
          element.style.marginTop = (28 - noteIndex) * 6 + 'px'
          element.style.background = hasFx ? 'red' : 'black'
        }

        document.getElementById('graph-' + uuid).append(element)

        lastNote = note

        // if (indexMin == null || noteIndex < indexMin) {
        //   indexMin = noteIndex
        // }
        // if (indexMax == null || noteIndex > indexMax) {
        //   indexMax = noteIndex
        // }
      })
    })

    // console.log(indexMin, indexMax)
  }

}
