<!-- <html> -->

<template>

  <div id="container">

      <div id="modal">

        <div id="close" @click="app.modalSeen = null">
         <img :src="require('../assets/images/buttons/info_blue_close.png')">
        </div>

        <!-- app.isLogged: {{ app.isLogged }} -->
        <!-- <br> -->
        <!-- token: {{ token ? token : '-' }} -->
        <!-- <br> -->
        <!-- profile.username: {{ profile ? profile.username : '-' }} -->

        <div id="profile" v-if="profile" >
          <span class="title">Mon profil</span>
          <br>
          <a
            id="logout"
            href="#"
            v-show="profile"
            @click="logout()"
          >me déconnecter</a>
          <br><br>

          <form id="profile-form" @submit.prevent="updateProfile()">

            <table style="margin:auto;text-align:left">
              <tr>
                <td>Pseudo :</td>
                <td>{{ profile.username }}</td>
              </tr>
              <tr>
                <td>eMail :</td>
                <td>{{ profile.email }}</td>
              </tr>
              <tr>
                <td>Nom :</td>
                <td>
                  <!-- {{ profile.last_name }} -->
                  <input
                    type="text"
                    name="last_name"
                    v-model="profile.last_name"
                  >
                </td>
              </tr>
              <tr>
                <td>Prénom :</td>
                <td>
                  <!-- {{ profile.first_name }} -->
                  <input
                    type="text"
                    name="first_name"
                    v-model="profile.first_name"
                  >
                </td>
              </tr>
            </table>

            <input type="submit" name="submit" value="Enregistrer">

          </form>

          <br>

          <span class="title">Changer de mot de passe</span>

          <form id="change-password" @submit.prevent="changePassword()">
            <table style="margin:auto;text-align:left">
              <tr>
                <td>Ancien mot de passe : </td>
                <td>
                  <input
                    type="password"
                    name="old_password"
                    v-model="form.changePwd.old_password"
                  >
                </td>
              </tr>
              <tr>
                <td>Nouveau mot de passe : </td>
                <td>
                  <input
                    type="password"
                    name="new_password1"
                    v-model="form.changePwd.new_password1"
                  >
                </td>
              </tr>
              <tr>
                <td>Confirmation :</td>
                <td>
                  <input
                    type="password"
                    name="new_password2"
                    v-model="form.changePwd.new_password2"
                  >
                </td>
              </tr>
            </table>

            <input type="submit" name="submit" value="Enregistrer">
          </form>

          <!-- <br> -->
          <!-- <br><br> -->

        </div> <!-- #profile -->

        <div v-else id="auth-forms">

          <div id="register">

            <p class="title">S'inscrire</p>

            <form id="register-form" @submit.prevent="register()">

              <div class="fields">

                <input
                  v-model="form.register.email"
                  type="email"
                  name="email"
                  placeholder="EMAIL"
                  required="true"
                >
                <br>
                <input
                  v-model="form.register.password1"
                  type="password"
                  name="password1"
                  placeholder="MOT DE PASSE"
                  required="true"
                >
                <input
                  v-model="form.register.password2"
                  type="password"
                  name="password2"
                  placeholder="CONFIRMATION DU MOT DE PASSE"
                  required="true"
                >
                <br>
                <input
                  v-model="form.register.username"
                  type="text"
                  name="username"
                  placeholder="PSEUDO"
                  required="true"
                >
                <br>
                <input
                  v-model="form.register.first_name"
                  type="text"
                  name="first_name"
                  placeholder="PRENOM"
                  required="true"
                >
                <br>
                <input
                  v-model="form.register.last_name"
                  type="text"
                  name="last_name"
                  placeholder="NOM"
                  required="true"
                >
              </div> <!-- .fields -->

              <input
                type="submit"
                name="submit"
                value="S'inscrire"
              >
            </form>

          </div> <!-- #register -->

          <div id="login">

            <p class="title">Se connecter</p>

            <form id="login-form" @submit.prevent="login()">

              <div class="fields">

                <div>
                  <input
                    v-model="form.login.username"
                    type="text"
                    name="username"
                    placeholder="PSEUDO"
                    required="true"
                  >
                  <br>
                  <input
                    v-model="form.login.password"
                    type="password"
                    name="password"
                    placeholder="MOT DE PASSE"
                    required="true"
                  >
                  <br>
                  <div class="alert" v-show="alertSeen.login">
                    Authentification impossible.
                    <br>
                    Veuillez vérifier votre pseudo et votre mot de passe.
                  </div>
                </div>
              </div> <!-- .fields -->

              <input type="submit" name="submit" value="Se connecter">
            </form> <!-- #login-form -->

          </div> <!-- #login -->
        </div> <!-- v-else -->
      </div> <!-- #modal -->

      <div id="background" @click="app.modalSeen = null"></div>
  </div>

</template>

<script>

export default {

  name: 'AuthModal',

  props: ['id'],

  data () {
    return {
      form: {
        register: {
          username: 'johnblack', // null
          password1: 'johnblack123456789', // null
          password2: 'johnblack123456789', // null
          email: 'john@black.com', // null
          first_name: 'John', // null
          last_name: 'Black', // null
        },
        login: {
          username: 'benjamin', // null
          password: 'benjamin', // null
        },
        changePwd: {
          old_password: null, // 'test12341234', //null
          new_password1: null, // 'hello12341234', //null
          new_password2: null, // 'hello12341234' //null
        }
      },
      alertSeen: {
        login: false
      }
    }
  },

  methods: {
    login: function() {
      this.$Request.Post('/auth/login/', this.form.login)
          .then((response) => {
            localStorage.setItem('token', response.data.key)

            this.$Request.Get('/profile/')
                .then(response => this.profile = response.data)

            this.alertSeen.login = false
            this.app.modalSeen = null
          })
          .catch(e => this.alertSeen.login = true)
    },

    logout: function() {
      this.$Request.Post('/auth/logout/')
          .then((response) => {
            localStorage.removeItem('token')
            this.profile = null
            this.app.modalSeen = null
          })
    },

    register: function() {
      const register = this.form.register

      this.$Request.Post('/auth/registration/', {
            username: register.username,
            password1: register.password1,
            password2: register.password2,
            email: register.email,
          })
          .then((response) => {
            localStorage.setItem('token', response.data.key)

            this.$Request.Put('/profile/update/', {
              first_name: register.first_name,
              last_name: register.last_name,
            })
            .then(() => {
              this.$Request.Get('/profile/')
                  .then(response => this.profile = response.data)
            })
            .catch(e => console.log(e))

          })
          .catch(e => console.log(e))
    },

    updateProfile: function() {
      this.$Request.Put('/profile/update/', {
        last_name: this.profile.last_name,
        first_name: this.profile.first_name,
      })
      .catch(e => console.log(e))
    },

    changePassword: function() {
      this.$Request.Post('/auth/password/change/', this.form.changePwd)
          .then((response) => { console.log(response) })
          .catch(e => console.log(e))
    }
  },

  computed: {
    app: function() {
      return this.$root
    },
    profile: {
      get: function() {
        return this.app.profile
      },
      set: function(profile) {
        this.app.profile = profile
      }
    },
  },

  // created() {}
}

</script>

<style scoped>

.d-flex {
  display: flex;
}

/**/

#container {
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#modal {
  width: 80vw;
  height: 85vh;
  margin: auto;
  margin-top: 10vh;
  background: #65c1cc;
  border-radius: 2vw;
  padding-top: 1vh;
  font-size: 2vw;
}

#close {
  position: absolute;
  left: 90vw;
  top: 1vh;
}

#close img {
  width: 8vw;
  height: auto;
}

#profile {
  margin-top: -5vh;
  width: 60vw;
  margin: auto;
  color: white;
}

#logout {
  text-decoration: none;
  font-size: 1.6vw;
  color: black;
}

#profile-form {
  width: inherit;
  height: 30vh;
}

#change-password {
  width: inherit;
  height: 30vh;
}

#auth-forms {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-around;
}

#profile td {
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-size: 2vw;
  text-transform: uppercase;
  color: white;
}

.fields {
  height: 50vh;
}

.avatar img {
  width: inherit;
}

form {
  width: 30vw;
  height: 70vh;
}

form input {
  height: 6vh;
  border: 1px solid #ccc;
  font-size: 4vh;
  font-family: inherit;
  margin-top: .5vh;
  border-radius: 1vw;
  padding-left: 2vh;
}

form input[name="submit"] {
  background: #f62d57;
  border-radius: 2vw;
  width: 18vw;
  height: 5vh;
  border: 0;
  font-family: inherit;
  font-size: 4vh;
  bottom: 0;
  text-transform: uppercase;
  color: white;
}

.alert {
  border: 2px solid red;
  color: red;
  font-size: 1.5vw;
  margin-top: 3vh;
}

#background {
  width: inherit;
  height: inherit;
  background: white;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

</style>
