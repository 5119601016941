<!-- <html> -->

<template>
<div>

  <div id="grid"> <!-- v-if="display.main == 'grid'" -->

    <div id="head">
      <div
        class="column"
      > <!-- :style="'height:' + columnHeight + 'vh'" -->
        <div
          v-for="instruments, family in families"
          :class="row != null && row != family ? 'row-collapsed' : ''"
          class="row instrument"
        >
          <!-- <a href="#" @click="row = row == family ? null : family">
            {{ family }}
          </a> -->

          <div
            @click="row = row == family ? null : family"
            style="display:flex;justify-content:center"
          >
            <img
              v-for="instrument in instruments"
              :src="require('../assets/images/instruments/' + instrument + '.png')"
              style="height:auto"
            >
          </div>

          <div
            v-for="instrument in instruments"
            v-show="row == family"
          >
            {{ instrument }}
          </div>
        </div>

        <div
          v-for="instruments, genre in genres"
          :class="row != null && row != genre ? 'row-collapsed' : ''"
          class="row genre"
        >

          <div @click="row = row == genre ? null : genre">
            {{ genre.toUpperCase() }}
          </div>

          <br>

          <div
            v-for="instrument in instruments"
            v-show="row == genre"
          >
            {{ instrument }}
          </div>

        </div>
      </div> <!-- .column -->

      <!-- <div class="play-cell"></div> -->

      <div
        id="sheet-head"
        class="measure"
        v-show="display.sheet"
      ></div> <!-- :style="'transform: scale(' + sheetScale + ')'" -->

      <div class="graph" v-show="display.graph"></div>

    </div>

    <div id="cells">

      <div
        v-for="cell, i in mix.datas"
        @click="selected = cell.uuid, seek(i)"
        :id="'cell-' + cell.uuid"
        :class="selected == cell.uuid ? 'selected' : ''"
        class="cell"
      >
        <div v-show="!iCanEdit" class="cell-overlay"></div>

        <div
          class="column"
        > <!-- :style="'height:' + columnHeight + 'vh'" -->
          <div
            v-for="instruments, family in families"
            :class="row != null && row != family ? 'row-collapsed' : ''"
            class="row"
          >
            <img
              v-if="sectionIsMuted[i][family]"
              @click="mute(families[family], false)"
              src="../assets/images/buttons/muted.png"
            >

            <img
              v-else
              @click="mute(families[family], true)"
              src="../assets/images/buttons/unmuted.png"
              class="button-mute"
            >

            <div
              v-for="instrument in instruments"
              v-show="row == family"
              @click="mute(instrument, !cell.mix[instrument])"
              class="instrument-mute-button"
            >
              <img
                v-if="cell.mix[instrument]"
                src="../assets/images/buttons/muted.png"
              >

              <img
                v-else
                src="../assets/images/buttons/unmuted.png"
              >
            </div>

          </div>

          <div
            v-for="instruments, genre in genres"
            :class="row != null && row != genre ? 'row-collapsed' : ''"
            class="row"
          >
            <img
              v-if="sectionIsMuted[i][genre]"
              @click="mute(genres[genre], false)"
              src="../assets/images/buttons/muted.png"
            >

            <img
              v-else
              @click="mute(genres[genre], true)"
              src="../assets/images/buttons/unmuted.png"
            >
            <!--
              selected = cell.uuid,
              seek(i),
           -->

            <div
              v-for="instrument in instruments"
              v-show="row == genre"
              @click="mute(instrument, !cell.mix[instrument])"
              class="instrument-mute-button"
            >
              <img
                v-if="cell.mix[instrument]"
                src="../assets/images/buttons/muted.png"
              >
              <img
                v-else
                src="../assets/images/buttons/unmuted.png"
              >
            </div>

          </div>

          <br>


        </div> <!-- .column -->

        <div class="play-cell" @click="playCell()">
          <img src="../assets/images/buttons/studio-play-cell.png">
        </div>

        <div
          :id="'measure-' + cell.uuid"
          class="measure"
          v-show="display.sheet"
        > <!-- :style="'transform: scale(' + sheetScale + ')'" -->
          {{ 'measure-' + cell.uuid }}
        </div>

        <div
          :id="'graph-' + cell.uuid"
          class="graph"
          v-show="display.graph"
          @click="playCell()"
        >
          {{ 'graph-' + cell.uuid }}
        </div>

      </div> <!-- :id="'cell-' + cell.uuid" -->
    </div> <!-- #cells -->
  </div> <!-- #grid -->

  <div
    id="controls"
    :class="selected != 0 ? 'selected' : ''"

  > <!-- v-show="display.main == 'grid'" -->

    <div id="controls-overlay" v-show="!iCanEdit"></div>

    <!-- <div class="control">
      <button @click="display.sheet = ! display.sheet">
        display.sheet
      </button>
      <br>
      <button @click="display.graph = ! display.graph">
        display.graph
      </button>
    </div> -->

    <div id="playing-control" class="control">
      <img
        v-if="!isPlaying"
        src="../assets/images/buttons/studio-play.png"
        @click="play()"
      >
      <img
        v-else
        src="../assets/images/buttons/studio-pause.png"
        @click="pause()"
      >
      <!-- <img
        src="../assets/images/buttons/stop.png"
        @click="stop()"
      > -->
    </div>

    <div class="control">
      <small>Inverser</small>
      <br>
      <img :src="require('../assets/images/buttons/reverse-'
        + (selectedCell ? (selectedCell.reverse ? 'orange' : 'green') : 'green')
        + '.png')"
          @click="reverse()"
      />
    </div>

    <div class="control">
      <small>Hauteur ({{ selectedCell ? selectedCell.tune : '.' }})</small>
      <br>
      <img
        src="../assets/images/buttons/tune-down.png"
        @click="tune('down')"
      >
      <img
        src="../assets/images/buttons/tune-up.png"
        @click="tune('up')"
      >
    </div>

    <div class="control">
      <small>Déplacer</small>
      <br>
      <img src="../assets/images/buttons/move-left.png" @click="move('left')">
      <img src="../assets/images/buttons/move-right.png" @click="move('right')">
    </div>

    <div class="control">
      <small>Dupliquer</small>
      <br>
      <img src="../assets/images/buttons/duplicate.png" @click="duplicate()">
    </div>

    <div class="control">
      <small>Supprimer</small>
      <br>
      <img src="../assets/images/buttons/remove.png" @click="remove()">
    </div>

  </div> <!-- #controls -->

</div>
</template>

<script>
import { store } from '../store/index.js'

export default {

  name: 'Studio',

  props: ['id'],

  data () {
    return {
      store,

      // tempo: 0,

      // display: {
      //   main: 'grid', // [ scene - grid ]
      //   sheet: true, // true
      //   graph: false, // false
      // },

      families: {
        percussions: ['percussions'],
        brasses: ['horn', 'trumpet', 'trombone', 'tuba'],
        woodwinds: ['flute', 'oboe', 'clarinet', 'bassoon'],
        strings: ['violin', 'viola', 'cello', 'contrabass'],
      },

      genres: {
        rock: ['electric_guitar', 'bass_guitar', 'rock_drums'],
        jazz: ['piano', 'pizz_bass', 'jazz_drums'],
        electro: ['synth_lead', 'synth_bass', 'electro_drums'],
      },

      row: null,
    }
  },

  methods: {
    play () {
      this.$Audio.play(this)
      this.store.setMainControl('play')
    },
    pause () {
      this.$Audio.pause(this.app)
      this.store.setMainControl('pause')
    },
    stop () {
      this.$Audio.stop(this.app)
      this.store.setMainControl('stop')
    },
    seek: function(i) {
      this.$Audio.seek(i)
    },
    playCell: function() {
      if (this.$Audio.getState() == 'started') {
        this.$Audio.stop()
      }
      this.$Audio.playCell()
    },

    switchMode() {
      if ( this.mode == 'info' ) {
        this.mode = 'mix'
      }
      else if ( this.mode == 'mix' ) {
        this.mode = 'info'
      }
    },

    /* Mix controls */
    mute: function(instruments, value = null) {
      setTimeout(() => {

        if (value == null) { return false }
        if (!Array.isArray(instruments)) { instruments = [instruments] }

        const rythmics = [
          'percussions',
          'rock_drums',
          'jazz_drums',
          'electro_drums'
        ]

        instruments.forEach((instrument) => {
          this.selectedCell.mix[instrument] = value

          if (value == false && rythmics.indexOf(instrument) != -1) {

            rythmics.forEach((rythmic) => {
              let mute = this.mix.datas[this.position].mix[rythmic]

              if (!mute && instrument != rythmic) {
                this.mix.datas[this.position].mix[rythmic] = true
              }
            })

          }
        })

      }, 50)

      // this.playCell()
    },

    /* Apply effects */
    reverse: function() {
      this.selectedCell.reverse = ! this.selectedCell.reverse

      if (this.display.sheet) {
        this.$Sheet.render(
          this.selectedCell.uuid,
          this.app.mixedScore[0][this.position].voices[1],
          this.selectedCell
        )
      }


      // this.playCell()
    },

    tune: function(sens) {
      let limit = 6

      const Tuner = {
        up: () => {
          if (this.selectedCell.tune <= limit) {
            this.selectedCell.tune ++
          }
        },
        down: () => {
          if (this.selectedCell.tune >= -limit) {
            this.selectedCell.tune --
          }
        }
      }

      Tuner[sens]()

      if (this.display.sheet) {
        this.$Sheet.render(
          this.selectedCell.uuid,
          this.app.mixedScore[0][this.position].voices[1],
          this.selectedCell
        )
      }

      // this.playCell()
    },

    /* Manage cells */
    move: function(sens) {
      let pos = this.uuids.indexOf(this.selectedCell.uuid)
      let newPos = 0

      switch (sens) {
        case 'left':
          newPos = pos - 1
          break
        case 'right':
          newPos = pos + 1
          break
      }

      this.mix.datas.splice(newPos, 0, this.mix.datas.splice(pos, 1)[0])

      if (this.display.sheet) {
        setTimeout(() => {
          this.$Sheet.render(
            this.selectedCell.uuid,
            this.app.mixedScore[0][this.position].voices[1],
            this.selectedCell
          )

          this.$Sheet.render(
            this.uuids[pos],
            this.app.mixedScore[0][pos].voices[1],
            this.mix.datas[pos]
          )
        }, 10)
      }

    },

    duplicate: function() {
      let pos = this.uuids.indexOf(this.selectedCell.uuid)
      let newPos = pos + 1
      let newCell = JSON.parse(JSON.stringify(this.selectedCell))

      newCell.uuid = new Date().getTime()
      this.mix.datas.splice(newPos, 0, newCell)
      this.selected = newCell.uuid

      if (this.display.sheet) {
        setTimeout(() => {
          this.$Sheet.renderAll(this.mix.datas, this.app.mixedScore)
        }, 10)
      }
    },

    remove: function() {
      let pos = this.uuids.indexOf(this.selectedCell.uuid)
      // let uuid = this.selectedCell.uuid

      this.mix.datas.splice(pos, 1)
      this.selected = 0

      // document.getElementById('cell-' + uuid).remove()

      if (this.display.sheet) {
        setTimeout(() => {
          this.$Sheet.renderAll(this.mix.datas, this.app.mixedScore)
        }, 10)
      }
    },

  },

  computed: {

    app: function() {
      return this.$root
    },

    isPlaying: function() {
      return this.app.isPlaying
    },

    display: function() {
      return this.app.display
    },

    mix: {
      get: function() {
        return this.app.mix
      },
      set: function(mix) {
        this.app.mix = mix
      }
    },

    selectedCell: function() {
      return this.app.selectedCell
    },

    uuids: function() {
      return this.app.uuids
    },

    nextCellUuid: function() {
      return this.app.nextCellUuid
    },

    position: function() {
      return this.app.position
    },

    selected: {
      get: function() {
        return this.app.selected
      },
      set: function(selected) {
        this.app.selected = selected
      }
    },

    sectionIsMuted: function() {
      return this.app.sectionIsMuted
    },

    iCanEdit: function() {
      if (!this.mix.id) {
        return true
      }

      if (this.mix.user && this.app.profile) {
        return this.mix.user.username == this.app.profile.username
      }

      return false
    },

    // columnHeight: function() {
    //   let height = 70
    //
    //   height -= this.display.sheet ? 10 : 0
    //   height -= this.display.graph ? 10 : 0
    //
    //   return height
    // },

    // sheetScale: function() {
    //   const width = window.innerWidth / 10
    //   const sheetScale = width / 200
    //
    //   return sheetScale
    // }
  },

  watch: {
    position: function(position) {
      if (this.isPlaying) {
        document.getElementById('cells').scrollTo({
          left: (position * window.innerWidth / 10) - 50,
          behavior: 'smooth'
        })
      }
    }
  }

  // created() {}

  // mounted() {}
}

</script>

<style scoped>

  #grid {
    width: 100vw;
    height: 80vh;
    margin-bottom: 2vh;
    display: flex;
  }

  #head {
    min-width: 25vw;
    text-align: center;
  }

  #head .row {
    border-radius: 2vw;
  }

  #head .row.instrument {
    background: #65c1cc;
  }

  #head .row.genre {
    background: #050209;
    color: white;
  }

  .row-collapsed {
    background: gray !important;
    border-radius: 2vw !important;
    min-height: 4vh !important;
    opacity: .7;
    margin-bottom: 1vh !important;
    /* transition: .5s; */
    max-width: 5vw;
    margin: auto;
  }

  .row-collapsed * {
    display: none;
  }

  #sheet-head {
    text-align: right;
    margin-top: 6vh;
    background: #fdf8db;
    border-radius: 1vw;
    width: 50%;
    margin-left: 50%;
  }

  #cells {
    width: 75vw;
    display: flex;
    overflow-y: hidden;
    width: auto;
    margin-top: .5vh;
  }

  .cell {
    min-width: 10vw;
    height: 75vh;
    background: #fdf8db;
    margin-left: .6vh;
    margin-right: .6vh;
    border-radius: 1.5vw;
  }

  .cell.selected {
    background: #65c1cc;
  }

  .cell-overlay {
    width: 10vw;
    height: 60vh;
    border-radius: 1.5vw;
    position: absolute;
    background: white;
    opacity: .5;
  }

  .column {
    padding-top: .5vh;
    font-size: 2vw;
    text-align: center;
    height: 60vh;
  }

  .row {
    min-height: 8vh;
  }

  .row img {
    max-width: 4vw !important;
  }

  .instrument-mute-button img {
    width: 2vw;
    height: auto;
    /* background: #eee; */
    /* border-radius: 1vw; */
  }

  /* .button-mute img {} */

  .play-cell {
    text-align: center;
    max-height: 5vh;
  }

  .play-cell img {
    width: 4vw;
    height: auto;
    margin-top: -1vh;
  }

  .measure {
    height: 10vh;
  }

  .graph {
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }


  #controls {
    width: 98vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    margin: auto;
    background: #fdf8db;
    border-radius: 1vw;
  }

  #controls.selected {
    background: #f62d57;
  }

  #controls-overlay {
    width: 80vw;
    height: inherit;
    margin-left: 18vw;
    border-radius: 1vw;
    position: absolute;
    background: white;
    opacity: .5;
  }

  .control {
    width: 15vw;
    text-align: center;
    justify-content: center;
    font-size: 1.8vw;
    text-transform: uppercase;
  }

  .control img {
    height: 6vh;
    width: auto;
    cursor: pointer;
  }

  #playing-control {
    padding-top: 2vh;
  }

</style>
