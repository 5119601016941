<!-- <html> -->

<template>

  <div id="container">

    <div id="modal">

      <!-- <div id="close" @click="app.modalSeen = null">x</div> -->

      <div id="close" @click="app.modalSeen = null">
        <img :src="require('../assets/images/buttons/info_blue_close.png')">
      </div>

      <!-- <div id="box"> -->

        <div id="title">
          OrchestraMix
        </div>

        <div id="sub-title">
          Découvrez tous les DJs et leurs mixs !
        </div>

        <br><br>

        <div id="nav-bar">
          <!-- <div>
            <button>
              le fil
            </button>
          </div> -->
          <div>
            <button
              @click="getUserList(), activeTab = 'users', user = null"
              :class="activeTab == 'users' ? 'activeTab' : ''"
            >
              <img :src="require('../assets/images/pictos/dj.png')">
              les DJs
            </button>
          </div>
          <div>
            <button
              @click="getMixList(), activeTab = 'mixs', mix = null"
              :class="activeTab == 'mixs' ? 'activeTab' : ''"
            >
              <img :src="require('../assets/images/pictos/mix.png')">
              les mixs
            </button>
          </div>
          <div>
            <button
              @click="profile ? getMixList(profile.id) : null, activeTab = 'profile'"
              :class="activeTab == 'profile' ? 'activeTab' : ''"
            >
              <img :src="require('../assets/images/pictos/me.png')">
              moi
            </button>
          </div>

        </div>

        <br>

        <div id="tabs">

          <div
            id="users"
            v-show="activeTab == 'users'"
            class="tab"
          >

            <div v-if="user">

              <div class="avatar">
                <img :src="require('../assets/images/buttons/profile.png')">
              </div>

              {{ user.first_name }} {{ user.last_name }}
              <!-- <br> -->
              ({{ user.username }})

              <br>

              {{ mixList.length }} mixs :
              <br><br>

              <div class="list" style="height:30vh;">
                <table>
                  <tr
                    v-for="mix in mixList"
                    @click="loadMix(mix.id)"
                    class="mix"
                  >
                    <td @click="getMix(mix.id)" class="mix-title">
                      <img
                        :src="require('../assets/images/pictos/mix.png')"
                        class="picto"
                      >
                      {{ mix.title }}
                    </td>
                    <td class="mix-likes">
                      <img
                        :src="require('../assets/images/buttons/'
                          + (iLikeMix(mix) ? 'liked' : 'unliked') + '.png')"
                      >
                      {{ mix.likes.length }}
                    </td>
                    <td class="mix-coms">
                      <img :src="require('../assets/images/buttons/comment.png')">
                      {{ mix.comments.length }}
                    </td>
                    <!-- <td class="mix-load">
                      <button @click="loadMix(mix.id)">charger</button>
                    </td> -->
                  </tr>
                </table>
              </div>

              <br>

              <button id="all-users" @click="user = null">
                &lt;&lt; tous les DJs
              </button>
            </div>

            <div v-else class="list" style="height:60vh;">

              <table>
                <tr
                  v-for="user in userList"
                  @click="getUser(user.username), getMixList(user.id)"
                >
                  <td class="username">
                    <img
                      :src="require('../assets/images/pictos/dj.png')"
                      class="picto"
                    >
                    {{ user.username }}
                  </td>
                  <td class="mixs-count">
                    {{ user.mixs.length }} mixs
                  </td>
                  <!-- <td class="followers-count">
                    X followers
                  </td> -->
                </tr>
              </table>

            </div>

          </div> <!-- #users .tab -->

          <div
            id="mixs"
            v-show="activeTab == 'mixs'"
            class="tab"
          >

            <!-- <div v-if="mix">

              {{ mix.title }} ({{ mix.user.username }})
              <br>
              description ...
              <br><br>

              likes list - coms list
              <br><br>

              <button name="loadMix" @click="loadMix(mix.id)">LOAD</button>

              <br><br>

            </div> -->

            <div class="list" style="height:60vh;"> <!-- v-else -->
              <table>
                <tr
                  v-for="mix in mixList"
                  @click="loadMix(mix.id)"
                  class="mix"
                >
                  <td @click="getMix(mix.id)" class="mix-title">
                    <img
                      :src="require('../assets/images/pictos/mix.png')"
                      class="picto"
                    >
                    {{ mix.title }}
                    <!-- <br> -->
                    <span style="font-size:1.5vw;">
                      (by {{ mix.user.username }})
                    </span>
                  </td>
                  <td class="mix-likes">
                    <img
                      :src="require('../assets/images/buttons/'
                        + (iLikeMix(mix) ? 'liked' : 'unliked') + '.png')"
                    >
                    {{ mix.likes.length }}
                  </td>
                  <td class="mix-coms">
                    <img :src="require('../assets/images/buttons/comment.png')">
                    {{ mix.comments.length }}
                  </td>
                  <!-- <td class="mix-load">
                    <button @click="loadMix(mix.id)">charger</button>
                  </td> -->
                </tr>
              </table>

              <br>

            </div>

          </div> <!-- #mixs .tab -->

          <div
            id="profile"
            v-show="activeTab == 'profile'"
            class="tab"
          >

            <div v-if="profile">

              <div class="avatar">
                <img :src="require('../assets/images/buttons/profile.png')">
              </div>

              {{ profile.first_name }}
              {{ profile.last_name }}
              <br>
              ({{ profile.username }})

              <br><br>

              <div class="list" style="height:40vh;">
                <table>
                  <tr
                    v-for="mix in mixList"
                    @click="loadMix(mix.id)"
                    class="mix"
                  >
                    <td @click="getMix(mix.id)" class="mix-title">
                      <img
                        :src="require('../assets/images/pictos/mix.png')"
                        class="picto"
                      >
                      {{ mix.title }}
                    </td>
                    <td class="mix-likes">
                      <img
                        :src="require('../assets/images/buttons/'
                          + (iLikeMix(mix) ? 'liked' : 'unliked') + '.png')"
                      >
                      {{ mix.likes.length }}
                    </td>
                    <td class="mix-coms">
                      <img :src="require('../assets/images/buttons/comment.png')">
                      {{ mix.comments.length }}
                    </td>
                    <!-- <td class="mix-load">
                      <button @click="loadMix(mix.id)">charger</button>
                    </td> -->
                  </tr>
                </table>
              </div> <!-- .list -->

            </div>

            <div v-else id="login">
              <button @click="app.modalSeen = 'auth'">
                Je m'inscris / je me connecte
                <br>
                pour retrouver tous mes mixs
                <br>
                et en partager de nouveaux
              </button>
            </div>

          </div> <!-- #profile .tab -->

        </div> <!-- #tabs -->
      <!-- </div> #box -->

      <div id="loading-modal" v-show="loading">
        <div id="loading-modal-box">
          -- Chargement du mix --
          <br><br>
          <div id="loading-bar">
            <div id="fill" :class="loading ? 'filled' : ''"></div>
          </div>
        </div>
        <div id="loading-modal-background"></div>
      </div>

    </div> <!-- #modal -->

    <div id="background" @click="app.modalSeen = null"></div>

  </div> <!-- #container -->

</template>

<script>

export default {

  name: 'NavModal',

  props: ['id'],

  data () {
    return {
      activeTab: 'users',

      userList: [],
      user: null,

      mixList: [],
      mix: null,

      loading: false
    }
  },

  methods: {
    getUserList: function() {
      this.$Request.Get('/user/list/')
          .then(response => this.userList = response.data)
    },
    getUser: function(user) {
      this.$Request.Get('/user/' + user + '/')
          .then(response => this.user = response.data)
    },
    getMixList: function(userId = null) {
      this.$Request.Get('/mix/list/' + (userId ? '?user=' + userId : ''))
          .then(response => this.mixList = response.data)
    },
    getMix: function(id) {
      this.$Request.Get('/mix/' + id + '/')
          .then(response => this.mix = response.data)
    },
    loadMix: function(id) {
      this.loading = true

      this.$Request.Get('/mix/' + id + '/')
          .then((response) => {
            this.app.mix = response.data

            setTimeout(() => {
              this.app.modalSeen = false
              this.loading = false
            }, 1000)
          })
    },

    like (mix) {
      this.app.like(mix)
    },

    unlike (mix) {
      this.app.unlike(mix)
    },

    iLikeMix (mix) {
      if (this.profile == null) {
        return false
      }

      let iLikeMix = false

      mix.likes.forEach((like) => {
        if (like.user.username == this.profile.username) {
          iLikeMix = true
        }
      })

      return iLikeMix
    },
  },

  computed: {
    app: function() {
      return this.$root
    },
    // isLogged: function() {
    //   return this.app.isLogged
    // },
    profile: function() {
      return this.app.profile
    }
  },

  created() {
    this.getUserList()
  },

  // mounted() {}

}

</script>

<style scoped>

a {
  text-decoration: none;
}

/* .activeTab {
  padding-bottom: .5vh;
  border-bottom: 4px solid red;
} */

#container {
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  font-size: 1.6em;
}

#modal {
  width: inherit;
  height: inherit;
  margin: auto;
  padding-top: 5vh;
  font-size: 2vw;
  background: #f5c920;
}

#close {
  position: absolute;
  left: 90vw;
  top: 1vh;
}

#close img {
  width: 8vw;
  height: auto;
}

#nav-bar {
  display: flex;
  justify-content: space-around;
  max-width: 50vw;
  margin: auto;
}

#title {
  font-size: 3vw;
  text-transform: uppercase;
}

#sub-title {
  font-size: 1.8vw;
  text-transform: uppercase;
}

#nav-bar button {
  background: white;
  border: 0;
  border-radius: 1vw;
  width: 15vw;
  height: 6vh;
  font-size: 1.5vw;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-bar button.activeTab {
  background: #f62d57 !important;
}

#nav-bar button img {
  width: 3vw;
  height: auto;
  margin-top: -1vh;
}

#tabs {
  width: 90%;
  height: 80%;
  margin: auto;
  /* border-top: 1px solid #ccc; */
  /* padding-top: 2vh; */
  /* border: 1px solid #ccc; */
  /* border-radius: 20px; */
  /* margin-top: 5vh; */
}

.tab {
  height: 60vh;
}

table {
  margin: auto;
  text-align: left;
  width: 70vw;
  font-size: 2vw;
}

td {
  height: 4vw;
  border-radius: 1vw;
}

/* table img {
  width: 3vw;
  height: auto;
} */

button {
  font-size: 1.2vw;
}

.username {
  background: white;
  width: 50%;
  padding-left: 5%;
}

.mixs-count {
  background: #65c1cc;
  width: 20%;
  padding-left: 5%;
}

.followers-count {
  background: #f0b5c9;
  width: 20%;
  padding-left: 5%;
}

.list {
  width: 70vw;
  margin: auto;
  border: 1px solid white;
  border-radius: 1vw;
  overflow-y: scroll;
}

.mix img {
  width: 4vw;
  height: auto;
}

.mix-title {
  background: white;
  width: 70%;
  padding-left: 5%;
}

.mix-likes {
  width: 10%;
  font-size: 1.8vw;
  text-align: center;
}

.mix-coms {
  width: 10%;
  font-size: 1.8vw;
  text-align: center;
}

.mix-load {
  width: 30%;
}

.mix-load button {
  width: 6vw;
  height: 3vw;
  background: #f62d57;
  border: 0;
  border-radius: .5vw;
  font-size: 1.8vw;
  font-family: inherit;
}

button#all-users {
  border: 0;
  border-radius: 1vw;
  padding: 1vw;
  background: #65c1cc;
  font-family: inherit;
  font-size: 2vw;
}

.avatar img {
  width: 5vw;
  height: auto;
}

#login button {
  border: 0;
  border-radius: 1vw;
  padding: 1vw;
  background: #65c1cc;
  font-family: inherit;
  font-size: 2vw;
}

#loading-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

#loading-modal-box {
  width: 50vw;
  height: 50vh;
  margin: auto;
  margin-top: 30vh;
  font-size: 3vw;
}

#loading-bar {
  width: 80%;
  height: 2vh;
  border: 1px solid gray;
  margin: auto;
}

#loading-modal-background {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: .8;
  z-index: -1;
}

#fill {
  background: orange;
  height: inherit;
  width: 0%;
}

.filled {
  width: 100% !important;
  transition: 1s !important;
}

#background {
  width: inherit;
  height: inherit;
  background: white;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.picto {
  width: 3vw;
  height: auto;
  margin-right: 1vw;
}

</style>
