export default {

  Axios: require('axios'),
  baseUrl: '',

  /**/

  init: function(url) {

    if (url == null || ! /https?:\/\//.test(url)) {
      const message = 'ERROR: NO BACKEND URL'
      console.error(message)
      document.body.innerHTML = `<h1 style="color:red">${ message }</h1>`

      return
    }

    this.baseUrl = url

    return this
  },

  /**/

  Request: function(method, url, data = null) {

    const token = localStorage.getItem('token')

    return this.Axios({
      method: method,
      url: this.baseUrl + url,
      headers: token ? { 'Authorization': 'Token ' + token } : null,
      data: data
    })
  },

  /**/

  Get: function(url, datas = null) {

    if (datas != null) {
      url += '?' + Object.keys(datas).map((data) => {
          return data + '=' + datas[data]
      }).join('&')
    }

    return this.Request('GET', url)
  },

  Post: function(url, datas = null) {
    return this.Request('POST', url, datas)
  },

  Put: function(url, datas = null) {
    return this.Request('PUT', url, datas)
  },

  Delete: function(url) {
    return this.Request('DELETE', url)
  },

  /**/

}
