<!-- <html> -->

<template>

<div id="container"> <!-- zoom:90%; transform: scale(0.9, 0.85) -->
<!-- v-if="display.main == 'scene'" -->

  <div id="main">

    <div id="orchestre">

      <div id="classique">
        <family
          v-for="family in orchestra"
          v-bind:key="family.id"
          v-bind:id="family.id"
          v-bind:ref="family.id"
        ></family>
      </div>

      <div id="lecture-controls">

        <div @click="isPlaying ? (mode == 'mix' ? pause() : stop()) : play()">
          <img :src="require('../assets/images/buttons/'
           + ( isPlaying ? 'pause' : 'play' )
           + '.png')
          ">
        </div>

      </div>

    </div> <!-- #orchestre -->

  </div> <!-- END #main -->

  <div id="aside">

    <div id="remix" v-show="mode == 'mix' || !isPlaying">
      <div
        class="infos infos-remix"
        v-show="mode == 'info' && !isPlaying"
        @click="remixModal = true"
      >
        <img src="../assets/images/buttons/info_red.png">
      </div>

      <genre
        v-for="genre in remix"
        v-bind:key="genre.id"
        v-bind:id="genre.id"
        v-bind:ref="genre.id"
      ></genre>
    </div>

    <div id="slides" v-show="mode == 'info' && isPlaying">
      <p>{{ currentSlideText }}</p>
    </div>

    <div id="piece">
      <div
        class="infos infos-piece"
        v-show="mode == 'info' && !isPlaying"
        @click="pieceModal = true"
      >
        <img src="../assets/images/buttons/info_red.png">
      </div>

      <div style="margin-top:-5vh;">
        <p id="piece-title">{{ piece.title }}</p>
        <p id="piece-composer">{{ piece.composer }}</p>
        <p>
          <img src="../assets/images/buttons/music.png" style="width:6vw;">
        </p>
      </div>

    </div>

    <div id="modal-piece" v-show="pieceModal">
      <div id="modal-container" @click="pieceModal = false">

        <div id="close">
          <img src="../assets/images/buttons/info_red_close.png">
        </div>

        <img
          src="../assets/images/buttons/music.png"
          style="width:10vw;height:auto"
        >

        <h2>{{ piece.title }}</h2>
        <h3>{{ piece.composer }}</h3>

        <div style="display:flex;padding:50px;margin:auto">

          <div id="piece-image">
            <img src="../assets/images/photos/britten.png">
          </div>

          <div id="piece-description" style="width:70vw">
            {{ piece.description }}
          </div>

        </div>

      </div>

    </div>

    <remix-modal
      v-bind:key="remixModal"
      v-bind:id="remixModal"
      style="position:absolute"
      v-show="remixModal"
    ></remix-modal>

  </div> <!-- #aside -->

</div> <!-- #container -->

</template>

<script>
import { store } from '../store/index.js'

import Family from './Family.vue'
import Genre from './Genre.vue'
import RemixModal from './RemixModal.vue'

export default {

  name: 'Orchestra',

  props: ['id'],

  components: {
    Family,
    Genre,
    RemixModal,
  },

  data () {
    return {
      store,

      pieceModal: false,
      remixModal: false,

      // mode: null,

      time: 0,
      interval: null,

      display: {
        main: 'scene', // [ scene - grid ]
        sheet: true,
        graph: false,
      },

    }
  },

  methods: {

    play () {
      if (this.mode == 'mix') {
        this.$Audio.play(this)
      }

      else if ( this.mode == 'info' ) {
        this.$Player.play()

        this.interval = setInterval( () => {
          this.time ++
        }, 1000)
      }

      this.store.setMainControl('play')
    },

    pause () {
      if (this.mode == 'mix') {
        this.$Audio.pause(this)
      }

      else if ( this.mode == 'info' ) {
        this.$Player.pause()
        this.interval = null
      }

      this.store.setMainControl('pause')
    },

    // stop () {
    //   if (this.mode == 'mix') {
    //     this.$Audio.stop(this)
    //   }
    //
    //   else if ( this.mode == 'info' ) {
    //     this.$Player.stop()
    //     clearInterval( this.interval )
    //     this.time = 0
    //   }
    //
    //   this.store.setMainControl('stop')
    // },

  },

  computed: {

    datas: function() {
      return this.$Datas
    },
    orchestra: function() {
      return this.$Datas.orchestra
    },
    remix: function() {
      return this.$Datas.remix
    },
    piece: function() {
      return this.$Datas.piece
    },
    isPlaying: function() {
      return this.store.mainControl == 'play'
    },
    mode: function() {
      return this.$root.mode
    },
    slides: function() {
      return this.$Datas.slides
    },
    currentSlide: function() {
      for (let i in this.slides) {
        if ( this.time >= this.slides[i].in
          && this.time <= this.slides[i].out
        ) {
          return this.slides[i]
        }
      }
    },
    currentSlideText: function() {
      return this.currentSlide ? this.currentSlide.text : null
    },

  },
}

</script>

<style scoped>

  #container {
    display: flex;
    transition: 1s;
    zoom: 95%;
  }

  #main {
    width: 75vw;
    padding-top: 10vh;
  }

  /* #orchestre {} */

  /* #classique {} */

  #lecture-controls {
    position: absolute;
    left: 32vw;
    top: 88vh;
    display: flex;
    cursor: pointer;
  }

  #lecture-controls img {
    width: 8vw;
    height: auto;
    margin: -18px;
    margin-top: 2vh;
  }

  #aside {
    width: 25vw;
    margin-top: 1vh;
  }

  #remix {
    height: 65vh;
    z-index: 0;
    padding-top: 2vh;
    background: #65c1cc;
    border-radius: 5vw;
  }

  .infos-remix {
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 85vw;
    top: -2vh;
  }

  .infos-remix img {
    width: 5vw;
    height: auto;
    margin-top: 5vh;
  }

  #slides {
    height: 65vh;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 2vh;
    font-size: 1.75vw;
    letter-spacing: 2px;
    background: #65c1cc;
    border-radius: 5vw;

    /* p {
      padding: 2.5vw;
    } */
  }

  #piece {
    width: 25vw;
    height: 20vh;
    color: black;
    text-align: center;
    font-family: 'Londrina Light';
    font-size: 1.2vmax;
    margin-top: 5%;
    padding-top: 8vh;
    background: #65c1cc;
    border-radius: 5vw;
  }

  .infos-piece {
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 85vw;
    top: 68vh;
  }

  .infos-piece img {
    width: 5vw;
    height: auto;
    margin-top: 5vh;
  }

  #piece-title {
    font-size: 1.5vmax;
  }

  /* #piece-composer {} */

  /* .infos img {} */

  #modal-piece {
    width: 100%;
    height: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #modal-container {
    width: inherit;
    height: inherit;
    background: #fb5914;
    z-index: 2;
    position: absolute;
    text-align: center;
  }

  #modal-container #close {
    position: absolute;
    top: 1vh;
    right: 2vw;
    font-weight: bold;
    cursor: pointer;
  }

  #modal-container #close img {
    width: 6vw;
    height: auto;
  }

  #piece-image {
    width: 30vw;
  }

  #piece-image img {
    width: 20vw;
    height: auto;
    border-radius: 10px;
  }

  #piece-description {
    width: 70vw;
    text-align: left;
    font-size: 1.5vmax;
  }

  #modal-piece button {
    float: right;
  }

</style>
