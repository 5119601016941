<!-- <html> -->

<template>
  <div class="remix">

    <div class="close" @click="close()">
      <img src="../assets/images/buttons/info_red_close.png">
    </div>

    <div class="title">
      <!-- <div class="title-background">
        <img
          src="../assets/images/backgrounds/instrument_title.png"
          style="width:inherit;height:inherit"
        >
      </div> -->
      <div class="">
        <h2>Les genres musicaux</h2>
      </div>
    </div>


    <div class="genres">

      <div
        v-for="genre in remix"
        :class="seenDescription == genre.id ? 'genre-active' : ''"
        class="genre"
      >

        <!-- <div style="
          width: inherit;
          height: inherit;
          position: absolute;
          z-index: 1;
        ">
          <img
            :src="require('../assets/images/backgrounds/instrument'
              + ( seenDescription == genre.id ? '-active' : '' )
              + '.png')"
            style="width:inherit;height:inherit;"
          >
        </div> -->

        <!-- <div class="title">{{ genre.title }}</div> -->

        <div class="title">

          <!-- <div class="title-background">
            <img
              src="../assets/images/backgrounds/instrument_title.png"
              style="width:inherit;height:inherit"
            >
          </div> -->
          <div class="">
            {{ genre.title }}
          </div>

        </div>

        <div
          v-if="seenDescription != genre.id"
          @click="showDescription( genre.id )"
        >

          <div class="content">
            <img
              :src="require('../assets/images/genres/'
                    + genre.files + '.png')">
          </div>

          <div class="infos">
            <img src="../assets/images/buttons/info_blue.png">
          </div>

        </div>

        <div v-else
          @click="hideDescription()"
          class="description"
        >

          <div class="content">
            <p>{{ genre.description }}</p>
          </div>


          <div class="infos">
            <img
              :src="require('../assets/images/buttons/'
                    + ( isPlaying ? 'pause' : 'play' )
                    + '.png')"
              @click.stop="!isPlaying ? play( genre.id ) : stop()"
            >
            <!-- <img src="../assets/images/buttons/info_red_close.png"> -->
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {

  name: 'RemixModal',

  props: ['id'],

  data () {
    return {
      seenDescription: null,
      isPlaying: false
    }
  },

  methods: {
    play ( genre ) {
      this.$Player.solo( genre )
      this.$Player.play()
      this.isPlaying = true
    },
    stop () {
      this.$Player.stop()
      this.$Player.unsolo()
      this.isPlaying = false
    },
    showDescription ( id ) {
      this.seenDescription = id
      if ( this.isPlaying ) {
        this.stop()
      }
    },
    hideDescription () {
      this.seenDescription = null
      if ( this.isPlaying ) {
        this.stop()
      }
    },
    close () {
      this.seenDescription = null
      this.$parent.remixModal = false

      if ( this.isPlaying == true ) {
        this.stop()
      }
    }

  },

  computed: {
    remix: function() {
      return this.$Datas.remix
    }
  },

  // watch: {
  //
  // },

  // created () {
  //
  // },

  // mounted() {
  //
  // }

}
</script>

<style scoped>

  .remix {
    width: 100%;
    height: 100vw;
    background: #f15bca;
    font-size: 1.5vmax;
    position: absolute;
    left: 0;
    top: 0;
  }

  .close {
    position: absolute;
    top: 1vh;
    right: 2vw;
    font-weight: bold;
    cursor: pointer;
  }

  .close img {
    width: 6vw;
    height: auto;
  }

  h2 {
    text-align: center;
  }

  .title {
    text-transform: uppercase;
    font-family: 'Londrina Light';

    min-height: 12vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #f33159;
    width: 28vw;
    height: 12vh;
    border-radius: 2vw;
    margin: auto;
    margin-top: 2vh;
  }

  .title-background {
    position: absolute;
    z-index: 0;
    width: 28vw;
    height: 12vh;
  }

  .genres {
    display: flex;
    justify-content: center;
  }

  .genre {
    width: 20vw;
    height: 70vh;
    cursor: pointer;
    /* background: #fdf8dc; */
    /* border-radius: 20px; */
    text-align: center;
    margin: 2vw;
    /* border: 1px solid red; */
    background: #fdf8de;
    border-radius: 2vw;
  }

  .genre-active {
    background: #f0b7cb !important;
  }

  .genre .title {
    text-transform: uppercase;
    font-family: 'Londrina Light';

    min-height: 12vh;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 12vw;
    height: 3vh;
    border-radius: 2vw;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .genre .title .title-background {
    position: absolute;
    z-index: -1;
    width: 15vw;
    height: 12vh;
  }

  .content {
    width: 90%;
    height: 45vh;
    margin: auto;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* border: 1px solid blue; */
  }

  .content img {
    max-width: 30vh;
    /* height: 35vh; */
  }

  .content p {
    font-size: 1.25vw;
    /* padding: 1vw; */
    text-align: left;
    text-indent: 2vw;
  }

  /* .description {} */

  /* .infos {} */

  .infos img {
    width: 80px;
    height: 80px;
  }

  .beige {
    background: #fdf8dc;
  }

  .jaune {
    background: #f6c920;
  }

</style>
