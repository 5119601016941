export default {

  tracks: {},

  init: function ( datas ) {

    // let baseUrl = '../assets/tracks/'

    for (let [key, family] of Object.entries(datas.orchestra)) {

      for (let [key, instrument] of Object.entries(family.instruments)) {

        let url = require( '../assets/tracks/' + instrument.files + '.mp3' )

        this.tracks[instrument.id] = new Howl({ src: [ url ] })

        this.tracks[instrument.id]
      }

    }

    for (let [key, genre] of Object.entries(datas.remix)) {

      let url = require( '../assets/tracks/' + genre.files + '.mp3' )

      this.tracks[genre.id] = new Howl({ src: [ url ] })
    }

    let url = require( '../assets/tracks/tutti-original.mp3' )

    this.tracks['tuttiOriginal'] = new Howl({ src: [ url ] })
  },

  play: function () {
    for (let [key, track] of Object.entries(this.tracks)) {
      track.play()
    }
  },

  pause: function () {
    for (let [key, track] of Object.entries(this.tracks)) {
      track.pause()
    }
  },

  stop: function () {
    for (let [key, track] of Object.entries(this.tracks)) {
      track.stop()
    }
  },

  mute: function ( family ) {
    // console.log( 'player mute', family )

    let instruments = family.instruments

    for (let [key, instrument] of Object.entries(instruments)) {
      this.tracks[instrument.id].mute(true)
    }
  },

  unmute: function ( family ) {
    // console.log( 'player unmute', family )

    let instruments = family.instruments

    for (let [key, instrument] of Object.entries(instruments)) {
      this.tracks[instrument.id].mute(false)
    }
  },

  /*
    mute( instrument ) {
      this.tracks[instrument].mute(true)
    }

    unmute( instrument ) {
      this.tracks[instrument].mute(false)
    }
  */

  solo: function ( instrument ) {
    for (let [key, track] of Object.entries(this.tracks)) {

      if ( key == instrument ) {
        track.volume(1.0)
      }
      else {
        track.volume(0) // 0.05
        // state instrument = low
      }
    }
  },

  unsolo: function () {
    for (let [key, track] of Object.entries(this.tracks)) {
      track.volume(1.0)
      // state instrument = 'normal'
    }
  },

  seek: function (seek) {
    for (let [key, track] of Object.entries(this.tracks)) {
      track.seek(seek)
    }
  },

  test: function ( instrument ) {
    console.log( this.tracks, ' test' )
    this.tracks[instrument].play()
  }

}
