import { createApp } from 'vue'

import App from './App.vue'
import store from './store'

import {Howl, Howler} from 'howler'


import Datas from './datas.js'
import Player from './modules/Player.js'
import Request from './Request.js'

import Score from './modules/Score.js'
import Audio from './modules/Audio.js'
import Sheet from './modules/Sheet.js'
import Graph from './modules/Graph.js'
import './registerServiceWorker'


const app = createApp(App)

app.config.globalProperties.$Datas = Datas
app.config.globalProperties.$Player = Player
app.config.globalProperties.$Request = Request

app.config.globalProperties.$Score = Score
app.config.globalProperties.$Audio = Audio
app.config.globalProperties.$Sheet = Sheet
app.config.globalProperties.$Graph = Graph

app.config.productionTip = false

app.mount('#app')
