export default {

  name: 'Océane et Benjamin APP !',
  version: '0.0 POC',

  piece: {
    title: 'Variations sur un thème de Purcell',
    composer: 'Benjamin Britten',
    description: 'Edward Benjamin Britten est né en 1913 en Angleterre et meurt en 1976. C’est un compositeur, chef d’orchestre, altiste et pianiste britannique. Variation sur un thème de Pucell est une œuvre orchestrale composée par Benjamin Britten. Elle a été écrite en 1946 dans le but d’initier les jeunes aux instruments de l’orchestre. C’est l’une des œuvres les plus connues du compositeur, et une des trois partitions les plus utilisées pour l’éducation musicale des enfants, avec Le Carnaval des animaux de Saint-Saëns et Pierre et le Loup de Prokofiev.'
  },

  slides: {
    1: {
      in: 1, // 0
      out: 19, // 19
      family: 'tutti',
      text: `Tout d'abord l'air principal, ou thème, est joué par l’orchestre entier.`
    },
    2: {
      in: 23, // 23
      out: 39, // 39
      family: 'woodwinds',
      text: `D'abord la famille des bois : les flûtes, les clarinette, les hautbois et les bassons.`
    },
    3: {
      in: 46, // 46
      out: 60, // 60
      family: 'brasses',
      text: `Et voilà les cuivres, les cors, les trompettes, les trombones et les tuba.`
    },
    4: {
      in: 65, // 65
      out: 80, // 80
      family: 'strings',
      text: `Voici maintenant l'arrangement pour la famille des cordes. Les violons, les altos, violoncelles et les contrebasses.`
    },
    5: {
      in: 82, // 82
      out: 95, // 95
      family: 'percussions',
      text: `Et pour finir, les percussions. Les tambours, les gongs … Tout ce sur quoi on tape.`
    },
    6: {
      in: 98, // 98
      out: 117, // 117
      family: 'tutti',
      text: `Puis le thème de Purcell est à nouveau joué par les quatre familles réunies soit l'orchestre complet.`
    }
  },

  orchestra: {
    percussions: {
      id: 'percussions',
      files: 'percussions',
      title: 'Les percussions',
      description: 'Les percussions lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      instruments: {
        percussions: {
          id: 'percussions',
          files: 'percussions',
          title: 'Les percus',
          description: 'La famille des percussions rassemble les instruments de musique dont le son est provoqué par la frappe ou le grattage d’une membrane ou d’un matériau résonnant. Ce sont les tout premiers instruments de musiques crées, et on les trouve généralement dans tous les genres musicaux.',
        }
      }
    },

    brasses: {
      id: 'brasses',
      files: 'brasses',
      title: 'Les cuivres',
      description: 'Les cuivres forment une famille d\'instruments à vent. Dans un cuivre les mouvements des lèvres posées sur l\'embouchure font vibrer l\'air expiré ce qui produit un son. De ce fait le saxophone où c\'est une anche qui fait vibrer l\'air n\'est pas un cuivre mais un bois. Son nom vient du métal (le cuivre) avec lequel il était fabriqué. Cependant aujourd\'hui la plupart des cuivres sont en laiton.',
      instruments: {
        horn: {
          id: 'horn',
          files: 'horn',
          title: 'Le cor',
          description: 'Le cor est un instrument à vent de la famille des cuivres et le musicien qui joue du cor est un corniste. Il produit les notes de la gamme par vibration des lèvres sur l’embouchure. Sa main gauche active trois ou quat- re palettes (ou pistons), pour changer la hauteur du son. La main droite est placée DANS le pavillon pour soutenir l’instrument.',
        },

        trumpet: {
          id: 'trumpet',
          files: 'trumpet',
          title: 'La trompette',
          description: 'La trompette est un instrument à vent appartenant à la famille des cuivres. Elle est constituée d’une embouchure, permettant au trompetiste de souffler et de produire un son provenant d’un long tube. Les premières trompettes datent de l’Antiquité.On trouve généralement la trompette dans les styles musicaux tels que la musique classique et le jazz.',
        },

        trombone: {
          id: 'trombone',
          files: 'trombone',
          title: 'Le trombone',
          description: 'Le trombone est un instrument de musique à vent faisant partie de la famille des cuivres. Cet instrument a une spécialité : une coulisse, les tubes s’emboîtent et coulissent l’un dans l’autre ce qui permet au tromboniste de faire un glissando, un glissement d’une note à l’autre. On retrouve le trombone dans de nombreux genres musicaux, de la musique classique au jazz, en passant par la salsa, le ska, le funk ou la musique militaire.',
        },

        tuba: {
          id: 'tuba',
          files: 'tuba',
          title: 'Le tuba',
          description: 'Le tuba est un instrument de musique appartenant à la famille des cuivres.Il peut avoir diverses tailles et formes, il est l’instrument le plus grave de la famille des cuivres. Pour modifier la hauteur des sons, le tubiste fait vibrer ses lèvres en agissant sur la quantité et la vitesse de l’air expulsé. On trouve le tuba à l’orchestre symphonique, à l’orchestre d’harmonie et les fanfares.',
        }
      },
    },

    woodwinds: {
      id: 'woodwinds',
      files: 'woodwinds',
      title: 'Les bois',
      description: 'La famille des bois désigne les instruments de musique dont le son est, ou était, émis par une pièce en bois. Même si l\'instrument est en métal, c\'est ce qui fait le son qui détermine la famille de l\'instrument.',
      instruments: {
        flute: {
          id: 'flute',
          files: 'flute',
          title: 'La flûte',
          description: 'La flûte traversière est une flûte particulière. En effet, le flûtiste joue de côté. La flûte traversière est un instrument à vent qui fait partie de la famille des bois. En effet, même si aujourd’hui la flûte traversière est faite de métal, son ancêtre, le traverso, était en bois. Elle est composée de systèmes mécaniques, des clés bouchant les trous pour produire différentes notes.',
        },

        oboe: {
          id: 'oboe',
          files: 'oboe',
          title: 'Le hautbois',
          description: 'Le hautbois est un instrument à vent qui fait partie de la famille des bois. Il est composé de systèmes mécaniques, des clés bouchant les trous pour produire différentes notes. Il a une forme conique, et la anche est composé de deux fines lamelles de roseau taillées et ajustées sur un tube en métal. On y produit le son qui permet de jouer. Le musicien qui joue du hautbois est un hautboïste.',
        },

        clarinet: {
          id: 'clarinet',
          files: 'clarinet',
          title: 'La clarinette',
          description: 'La clarinette est un instrument de musique à vent, de la famille des bois. Le son de la clarinette est produit par les vibrations d’une anche simple en bois, une fine lamelle taillée dans la tige d’un roseau particulier appelé «canne de Provence». Le bec, qui porte l’anche, est le plus souvent en ébonite (matière plastique très dure), parfois en cristal.',
        },

        bassoon: {
          id: 'bassoon',
          files: 'bassoon',
          title: 'Le basson',
          description: 'Le basson est un instrument de musique de la famille des bois et des vents qui apparaît à la fin du XVIe siècle en Italie sous le nom de fagotto, car il ressemble un peu à un fagot de bois. Il est formé d’un long tuyau conique replié sur lui-même. L’anche double en roseau est fixée au bout d’un tuyau métallique de 30 cm, également conique et en forme de point d’interrogation, appelé bocal ou col de cygne.',
        }
      }
    },

    strings: {
      id: 'strings',
      files: 'strings',
      title: 'Les cordes',
      description: 'La famille des cordes désigne les instruments de musique dont le son est émis par des cordes tendues qui sont frappées, pincées ou frottées. L\'histoire des instruments à cordes est vieille de plusieurs milliers d\'années. Les premiers n\'avaient probablement qu\'une seule corde, comme l\'arc musical. Dès l\'Égypte ancienne, on connaissait les joueurs de harpe. Au Moyen Âge, les ménestriers s\'accompagnaient au luth.',
      instruments: {
        violin: {
          id: 'violin',
          files: 'violin',
          title: 'Le violon',
          description: 'Le violon est un instrument de musique de la famille des instruments à cordes frottées. Il possède quatre cordes qui sont frottées grâce à un archet, mais que l’on peut aussi pincer. Il est beaucoup utilisé dans les orchestres mais aussi, aujourd’hui, dans la variété, le jazz, la musique contemporaine. Les cordes sont en acier et la caisse de résonance en bois, souvent d’épicéa.',
        },

        viola: {
          id: 'viola',
          files: 'viola',
          title: 'L\'alto',
          description: 'L’alto est un instrument à cordes frottées, sa caisse a des dimensions plus grandes que celles du violon. Il a un registre plus grave que celui du violon, mais moins que celui du violoncelle. Il se joue debout avec un archet et est composé de 4 cordes (do, sol, ré, la). Il accompagne souvent les chansons du violon. Dans un groupe de musique, les altistes sont situés au premier rang et au milieu, devant le chef d’orchestre.',
        },

        cello: {
          id: 'cello',
          files: 'cello',
          title: 'Le violoncelle',
          description: 'Le violoncelle est un instrument à cordes frottées (on utilise un archet pour frotter les cordes anfin qu’elles pro- duisent un son). Le violoncelliste peut parfois pincer les cordes de son instrument à la manière d’un joueur de harpe, on appelle cela le pizzicato. Le violoncelle est un instrument possédant quatre cordes (do, sol, ré, la). Cet instrument se joue assis, tenu entre les jambes, appuyé sur le sol à l’aide d’une pique.',
        },

        contrabass: {
          id: 'contrabass',
          files: 'contrabass',
          title: 'La contrebasse',
          description: 'La contrebasse est un instrument semblable au violoncelle, mais plus volumineux et plus grave. C’est un des plus grand instrument de la famille des cordes frottées. Contrairement à l’alto et au violoncelle, les notes produites par ses cordes sont mi-la-ré-sol, comme le violon. La contrebasse est beaucoup utilisée dans le jazz, le plus souvent en pizzicato, c’est-à-dire en cordes pincées.',
        }
      }
    }
  },

  remix: {
    rock: {
      id: 'rock',
      files: 'rock',
      title: 'Rock\'n\'roll',
      description: 'Le rock’n’roll ou rock est un genre musical apparu dans les années 50 aux États-Unis influencé par le blues, le jazz et la musique country. Devenu populaire dès la fin des années 50, le rock a ensuite évolué pour donner naissance à d’autres styles, comme le hard rock ou le punk rock. Caractérisé par des instruments comme la guitare, la basse et la batterie, le rock a donné naissance à de nom- breux genres musicaux, qui ont fait le succès de beaucoup de groupes. Ainsi, The Beatles ou The Rolling Stones sont devenus deux des groupes britanniques les plus populaires',
      instruments: {
        rock: {
          id: 'rock',
          files: 'rock',
          title: 'Le rock',
          description: '',
        }
      }
    },

    jazz: {
      id: 'jazz',
      files: 'jazz',
      title: 'Jazz',
      description: 'Le jazz est un genre de musique né aux États-Unis au début du XXe siècle par les noirs américains. Cette musique vient du croisement du blues, du ragtime et de la musique classique. Le terme de jazz sera popularisé dès 1917 avec Louis Armstrong. Les principales influences du jazz sont les chants religieux (Negro spirituals) et les chants de travail (work songs) des esclaves noirs dans les plantations.',
      instruments: {
        jazz: {
          id: 'jazz',
          files: 'jazz',
          title: 'Le jazz',
          description: '',
        }
      }
    },

    electro: {
      id: 'electro',
      files: 'electro',
      title: 'Électro',
      description: 'La musique électronique est un type de musique conçu dans les années 1950 avec des générateurs de signaux et de sons synthétiques souvent composée de boucles répétitives.',
      instruments: {
        electro: {
          id: 'electro',
          files: 'electro',
          title: 'Le electro',
          description: '',
        }
      }
    },

    // hiphop: {
    //   id: 'hiphop',
    //   files: 'hiphop',
    //   title: 'Le HipHop',
    //   description: 'Le HipHop n roll bla bla',
    //   instruments: {
    //     foo: {
    //       id: 'foo',
    //       files: 'foo',
    //       title: 'Le foo',
    //       description: 'Le foo blabla blabla',
    //     }
    //   }
    // }
  },


  rythmic: {
    0: 'percussions',
    1: 'rock',
    2: 'jazz',
    3: 'electro'
  }


  // orchestra() {
  //   return this.orchestra
  // }

}
